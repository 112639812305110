
import { defineComponent } from "vue";
import { IonAvatar, IonCol, IonGrid, IonItem, IonLabel, IonList, IonNote, IonRow } from "@ionic/vue";
import { ItemsList } from "@/components/List/ItemList";
import UsePushLink from "@/composables/usePushLink";
import useDateManager from "@/composables/useDateManager";
import moment from "moment-timezone";

export default defineComponent({
  name: 'TheList',
  components: {
    IonItem,
    IonLabel,
    IonList,
    IonNote,
    IonAvatar,
  },
  props: {
    itemsList: Object as () => ItemsList[]
  },
  setup() {
    const { simplePush } = UsePushLink()
    const { formatHourHtml } = useDateManager()

    return {
      simplePush,
      formatHourHtml
    }
  }
})
