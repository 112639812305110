<template>
  <ion-page>
    <TheHeaderLarge subseccion="menu.disertante" />
    <ion-content fullscreen>
      <div v-if="speaker" >
        <AppInfoGtm v-if="presentations.length"/>
        <TheCard>
          <template #header>
            <ion-avatar style="margin: 1.5em auto; width: 100px; height: 100px">
              <img :src="speaker.avatar ? speaker.avatar : '/img/avatar.svg'">
            </ion-avatar>
          </template>
          <template #content>
            <div
              class="ion-margin"
              style="margin-top: 3em; margin-bottom: 3em"
            >
              <h1>
                {{ speaker.nombre }}
              </h1>
              <h4>
                {{ speaker.cargo }}
              </h4>
              <ion-text class="ion-margin">
                <template v-html="html"></template>
              </ion-text>
            </div>
          </template>
        </TheCard>

        <TheCard v-if="presentations.length">
          <template #header>
            <h3>Participa en</h3>
          </template>
          <template #content>
            <TheList :items-list="presentations" />
          </template>
        </TheCard>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonAvatar,
  IonContent,
  IonPage, IonText,
} from '@ionic/vue';
import { defineComponent, onMounted, ref } from 'vue';
import TheHeaderLarge from "@/components/TheHeaderLarge.vue";
import { Speaker } from "@/interfaces/Program/Speaker";
import { useRoute } from "vue-router";
import TheList from "@/components/List/TheList.vue";
import { ItemsList } from "@/components/List/ItemList";
import TheCard from "@/components/Card/TheCard.vue";
import { speakerService } from "@/services/speaker.service";
import UsePushLink from "@/composables/usePushLink";
import AppInfoGtm from "@/components/GTM/AppInfoGtm.vue";

export default defineComponent({
  name: 'Speaker',
  components: {
    AppInfoGtm,
    TheCard,
    TheList,
    TheHeaderLarge,
    IonContent,
    IonPage,
    IonAvatar,
    IonText
  },
  setup() {
    const route = useRoute()
    const eventId: string = route.params.eventId.toString()
    const speakerId: string = route.params.speakerId.toString()
    const { getUrl } = UsePushLink()
    const speaker = ref<Speaker>()
    const presentations = ref<ItemsList[]>([])

    const html = '<p>sadsadas</p>'
    onMounted(async () => {
      const speakerData = await speakerService.show(eventId, speakerId)
      speaker.value = speakerData
      if(speakerData.presentaciones){
        presentations.value = speakerData.presentaciones.map((presentation) => {
          return {
            hour: presentation.hora,
            title: presentation.titulo,
            category: presentation.keynote ? 'Keynote' : undefined,
            important: (presentation.keynote),
            link: getUrl('Presentation', { eventId: eventId, presentationId: presentation.id.toString() }),
          }
        })
      }
    })

    return {
      speaker,
      presentations,
      html
    }
  }
});
</script>

<style scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>