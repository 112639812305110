<template>
  <ion-footer>
    <ion-toolbar>
      <ion-row>
        <ion-col
          size="12"
          class="ion-text-center ion-margin-vertical"
        >
          <h4>{{ t('idiomas.veridiomas') }}</h4>
        </ion-col>
        <ion-col class="ion-text-center ion-no-padding ion-no-margin">
          <ion-button
            fill="clear"
            expand="full"
            @click="changeLang('es')"
          >
            <h3 class="ion-text-uppercase">
              {{ t('idiomas.es') }}
            </h3>
          </ion-button>
        </ion-col>
        <ion-col class="ion-text-center ion-no-padding ion-no-margin">
          <ion-button
            fill="clear"
            expand="full"
            @click="changeLang('en')"
          >
            <h3 class="ion-text-uppercase">
              {{ t('idiomas.en') }}
            </h3>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-toolbar>
  </ion-footer>
</template>

<script lang="ts">

import { defineComponent } from "vue";
import {
  IonFooter,
  IonRow,
  IonCol,
  IonToolbar,
  IonButton
} from '@ionic/vue';
import {
  gridOutline,
  arrowBackOutline,
  homeOutline,
  ribbonOutline,
  calendarOutline,
  personCircleOutline
} from 'ionicons/icons';
import UsePushLink from "@/composables/usePushLink";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default defineComponent({
  name: 'AppTabsBarLang',
  components: {
    IonFooter,
    IonRow,
    IonCol,
    IonToolbar,
    IonButton
  },
  props: {
    tabs: Array
  },
  setup() {
    const { params } = useRoute()
    const { toPush, toBack, getUrl } = UsePushLink()
    const { t } = useI18n()
    const { commit } = useStore()
    const { locale } = useI18n()

    const changeLang = (lang: string) => {
      commit('SET_LOCALE',  lang)
      locale.value = lang
    }

    return {
      t,
      getUrl,
      toPush,
      toBack,
      gridOutline,
      arrowBackOutline,
      homeOutline,
      ribbonOutline,
      calendarOutline,
      personCircleOutline,
      params,
      changeLang
    }
  }

})
</script>

<style scoped>
.ion-margin-vertical {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>