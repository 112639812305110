<template>
  <ion-menu side="start" content-id="main">
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>{{ t(title) }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item button @click="toPush( 'Profile', { eventId: 2})" >{{ t('menu.miPrefil') }}</ion-item>
        <ion-item button @click="toPush( 'Agenda', { eventId: 2})" >{{ t('menu.miAgenda') }}</ion-item>
        <ion-item button @click="logOut">{{ t('menu.cerrar') }}</ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'
import {
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonMenu,
  IonTitle,
  IonToolbar,
} from '@ionic/vue'
import { useI18n } from "vue-i18n";
import usePushLink from "@/composables/usePushLink";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonMenu,
    IonTitle,
    IonToolbar,
  },
  props: {
    title: {
      type: String,
      default: 'menu.menu',
    },
    state: {
      type: Boolean,
      default: true,
    }
  },
  setup() {
    const { t } = useI18n()
    const { toPush } = usePushLink()
    const { dispatch } = useStore()

    const logOut = async ()=>{
      await dispatch('logOut')
    }

    return {
      t,
      toPush,
      logOut
    }
  },
})
</script>
