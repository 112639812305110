import { EventShow } from "@/interfaces/EventShow";
import { ItemsListPresentation } from "@/components/List/ItemList";

interface AuthUserInterface {
  apellido: string;
  email: string;
  id: number;
  nombre: string;
  saludo: string;
  token: string;
  avatar: string;
}


interface StateInterface {
  locale: string;
  authUser?: AuthUserInterface;
  event?: EventShow;
  myAgenda?: {
   [key: number|string]: ItemsListPresentation[];
  };
}


const state: StateInterface = {
  locale: 'es',
}

export default state
