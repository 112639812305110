
import { defineComponent, reactive, ref } from 'vue'
import { IonButton, IonPage, IonContent } from '@ionic/vue'
import { Form, useForm } from 'vee-validate'
import AppGenderSelect from '@/components/Form/AppGenderSelect.vue'
import AppTextArea from '@/components/Form/AppTextArea.vue'
import AppInputText from '@/components/Form/AppInputText.vue'
import AppInputTel from '@/components/Form/AppInputTel.vue'
import AppCountrySelect from '@/components/Form/AppCountrySelect.vue'
import AppSelectIdiom from '@/components/Form/AppIdiomSelect.vue'
import AppInterestRadio from '@/components/Form/AppInterestRadio.vue'
import AppInputFecha from '@/components/Form/AppInputFecha.vue'
import AppInputUrl from '@/components/Form/AppInputUrl.vue'
import AppProfessionSelect from '@/components/Form/AppProfessionSelect.vue'

export default defineComponent({
  name: 'Test',
  components: {
    AppCountrySelect,
    AppInputText,
    AppInputTel,
    AppTextArea,
    AppInterestRadio,
    AppGenderSelect,
    AppSelectIdiom,
    AppInputFecha,
    IonPage,
    Form,
    IonButton,
    IonContent,
    AppInputUrl,
    AppProfessionSelect,
  },
  setup() {
    const required = ref(true)
    const form = reactive({
      paisNacimiento: undefined,
      paisResidencia: 1,
      gender: undefined,
      descripcion: '',
      texto: 'ss',
      tel: '',
      lang: 1,
      interes: undefined,
      fecha: undefined,
      url: undefined,
      profesion: undefined,
    })
    const { handleSubmit } = useForm()

    const onSubmit = handleSubmit((values) => {
      alert(JSON.stringify(values, null, 2))
    })

    return {
      required,
      form,
      onSubmit,
    }
  },
})
