
import {
  IonContent,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol, IonText
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { calendar, personCircle } from 'ionicons/icons';
import TheLangSelector from "@/components/TheLangSelector.vue";
import TheFooter from "@/components/TheFooter.vue";
import TheHomeButtom from "@/components/TheHomeButtom.vue";

export default defineComponent({
  name: 'Registration',
  components: {
    TheHomeButtom,
    TheFooter,
    TheLangSelector,
    IonContent,
    IonPage,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonText
  },
  setup() {
    console.log('Registration')

    const beforeTabChange = () => {
      // do something before tab change
    }
    const afterTabChange = () => {
      // do something after tab change
    }

    return {
      calendar,
      personCircle,
      beforeTabChange,
      afterTabChange
    }
  }

});
