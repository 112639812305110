const en = {
  derechos: 'All rights reserved',
  idiomas: {
    veridiomas: 'Change language',
    es: 'Spanish',
    en: 'English'
  },
  textos: {
    titulos: {
      activamenteInvolucradoEvento: 'Actively Involved Event',
      direccion: 'Mailing address',
      direccionTrbajoActual: 'Current work address',
      solicitudRegistro: 'Registration request',
      registroCompletado: 'Confirmation of e-mail required',
      activacionUsuario: 'User activation',
      definirClave: 'Define a password',
      solicitudCambioContrasena: 'Request password change',
      solicitudCambioContrasenaCompletada: 'Password change request',
      formularioCambioClave: 'Change of password',
      definirNuevaClave: 'New Password',
      abmUsuarios: 'Users',
      abmUsuariosListar: 'List',
      informacionPersonal: 'Personal information',
      datosDeContacto: 'Contact information',
      redesSociales: 'Social networks',
      informacionLaboral: 'Professional experience ',
      interesesEnElEvento: 'Interest group',
      otrosIntereses: 'Other interests',
      sector: 'Sector',
      sectoresOtros: 'Another sector',
      confirmacionsolicitud: 'Your scholarship application was successfully received.',
      leaAtentamente: 'Please read the following information carefully',
      importante: 'Important',
      notaImportanteSolicitud: 'You hereby authorize the CCAT LAT to mention your participation in the event, in order to promote it through promotional materials (physical and digital), such as newspaper advertisements, reports, websites, social networks and other appropriate means of publication in print, audio, photography, video, in person or virtual format. If for personal or professional reasons you want to limit the public exposure of your name or image, you must notify the organizing entities in advance to fulfill the request at info@gobernanzainternet.org. Likewise, you grant authorization to the organizers to collect and share your name, address or email address with other partners and co-organizers.',
      cancelacionBeca: 'Fellowship cancellation',
      cartaInvitacion: 'Letter of Invitation',
      informacionDeBeca: 'Fellowship information',
      tipoBeca: 'Type of Fellowship',
      estadoSolicitud: 'Your request has been recieved and it is in process',
      memorandumEntendimiento: 'Memorandum of understanding',
      compartirDatos: 'Share data'
    },
    subtitulos: {
      solicitudRegistro: 'Once the registration is completed, you can verify your email and establish a password',
      activacionUsuario: 'User activation',
      registroCompletado: 'We have sent an email, to complete the process check your email box including the spam folder.',
      abmUsuariosEditar: 'Edit',
      confirmacionsolicitud: 'We will inform you soon if you will be awarded the corresponding scholarship.',
    },
    parrafos: {
      solicitudRegistro: 'Once the registration is completed, you can verify your email and establish a password',
      activacionUsuario: 'To finish the activation of the user you must establish a new password',
      solicitudCambioContrasena: 'Request password change',
      cancelacionBeca: 'If for any reason you wish to cancel the scholarship. Click on the following button',
      cartaInvitacion: 'You can use the following document to present it at your workplace, educational institution or any other relevant body; who needs to be informed that their scholarship has been approved, for example to grant authorization in case of accepting the scholarship and / or to justify absenteeism',
      limiteAceptacion: 'You have time until the day [solicitud.vencimientoAprobacion] to <strong> inform your decision </strong>, otherwise the scholarship will be automatically rejected',
      memorandumEntendimiento: 'In case of accepting the conditions of the memorandum of understanding. Complete the following field with the registration email and then click <strong> "Accept" </strong> otherwise click <strong> "Cancel" </strong>'
    },
    preposiciones: {
      del: 'Between',
      al: 'and'
    },
    generales: {
      estado: 'State',
      estadoAceptandoSolicitud: 'Accepting requests',
      estadoCerrado: 'Closed',
      limiteDeAceptacion: 'Deadline for acceptance of applications',
      fechaAsignacion: 'Date of assignment',
      quedan: 'Remain',
      diasRestantes: 'days for the allocation limit',
      trabajoActualResponsabilidad: 'Responsibility',
      eventosDisponibles: 'Available events'
    }
  },
  formularios: {
    activamenteInvolucrado: {
      label: 'Are you actively involved in Internet Governance participation spaces such as ITU, ICANN, LACNIC, IETF, IGF, ISOC, LACigf, LACTLD or others?',
      placeholder: '',
      descripcion: 'Having this experience is not a requirement to apply for the scholarship. If the answer is yes, tell us which ones and how you are involved.'
    },
    apellido: {
      label: 'Surnames',
      placeholder: 'Full last name'
    },
    parteApellido: {
      label: 'Name',
      placeholder: 'Enter part of the last name'
    },
    celular: {
      label: 'Cellular',
      placeholder: 'Cell phone number'
    },
    ciudad: {
      label: 'City',
      placeholder: 'Enter the city'
    },
    ciudadResidencia: {
      label: 'City of residence',
      placeholder: 'Enter the city'
    },
    codigo: {
      label: 'Code',
      placeholder: 'Enter the code'
    },
    codigoTipodocumento: {
      label: 'Document type code',
      placeholder: 'Enter the code'
    },
    codigoPais: {
      label: 'Country code',
      placeholder: 'Enter the code'
    },
    cuit: {
      label: 'CUIT',
      placeholder: 'CUIT number'
    },
    descripcion: {
      label: 'Description',
      placeholder: 'Enter your description here'
    },
    calle: {
      label: 'Street',
      placeholder: 'Enter your street'
    },
    codigoPostal: {
      label: 'Postal Code',
      placeholder: 'Enter the postal code'
    },
    contrasena: {
      label: 'Password',
      placeholder: 'Enter your password'
    },
    contrasenaconfirmacion: {
      label: 'Confirm Password',
      placeholder: 'Re-enter your password'
    },
    departamento: {
      label: 'Department',
      placeholder: 'Enter your department'
    },
    interes: {
      label: 'Which stakeholder represents you best?',
      placeholder: ''
    },
    interesOtros: {
      label: 'What other interest represents you best?',
      placeholder: ''
    },
    localidad: {
      label: 'Location',
      placeholder: 'Select an option'
    },
    numeroDePuerta: {
      label: 'Door',
      placeholder: ''
    },
    piso: {
      label: 'Floor',
      placeholder: 'Enter the floor'
    },
    directorioS3: {
      label: 'S3 Directory',
      placeholder: 'S3 Path'
    },
    doumentoNumero: {
      label: 'Document number',
      placeholder: 'Enter your number'
    },
    documentoTipo: {
      label: 'Document type',
      placeholder: 'Select an option'
    },
    edad: {
      label: 'Age',
      placeholder: 'Enter an age',
    },
    edadDesde: {
      label: 'Age',
      placeholder: 'since',
    },
    edadHasta: {
      label: 'Age',
      placeholder: 'until',
    },
    email: {
      label: 'Email',
      placeholder: 'Enter your email',
    },
    estado: {
      label: 'State',
      placeholder: 'Select an option',
    },
    fecha: {
      label: 'Date',
      placeholder: 'Select a date',
    },
    fechaNacimiento: {
      label: 'Date of birth',
      placeholder: 'select a date'
    },
    fechaFin: {
      label: 'End date',
      placeholder: 'select a date'
    },
    fehaInicio: {
      label: 'Start date',
      placeholder: 'select a date'
    },
    genero: {
      label: 'Gender',
      placeholder: 'Select an option'
    },
    horaFin: {
      label: 'Ending time',
      placeholder: 'select end time'
    },
    horaInicio: {
      label: 'Start time',
      placeholder: 'select start time'
    },
    idioma: {
      label: 'Preferred language',
      placeholder: 'Select language',
    },
    limite: {
      label: 'Limit of records',
      placeholder: 'Select limit of records',
      opciones: 'To show '
    },
    nivel: {
      label: 'level',
      placeholder: 'Select a level'
    },
    nombre: {
      label: 'Names',
      placeholder: 'Full name'
    },
    parteNombre: {
      label: 'Name',
      placeholder: 'Enter part of the name'
    },
    pais: {
      label: 'Country',
      placeholder: 'Enter the country'
    },
    paisResidencia: {
      label: 'Country of residence',
      placeholder: 'Enter your residence'
    },
    preseleccionados: {
      label: 'Show preselected only',
      placeholder: 'Select an option'

    },
    profesion: {
      label: 'Profession',
      placeholder: 'Enter a profession',
    },
    primeraReferencia: {
      label: 'First reference',
      placeholder: '',
      descripcion: 'Complete information: name, postal address, email and telephone'
    },
    profesionPrevia: {
      label: 'Previous professional experience',
      placeholder: ''
    },
    provincia: {
      label: 'Province',
      placeholder: 'select an option'
    },
    redesSociales: {
      placeholder: 'Enter profile url',
    },
    responsabilidadesTrabajoActual: {
      label: 'Please explain briefly what your responsibilities are.',
      placeholder: 'Enter the description',
    },
    rol: {
      label: 'Role',
      placeholder: 'select an option'
    },
    sector: {
      label: 'Sector',
      placeholder: 'Allows multiple selections',
    },
    segundaReferencia: {
      label: 'Second reference',
      placeholder: '',
      descripcion: 'Complete information: name, postal address, email and telephone'
    },
    solicitudEstado: {
      label: 'Request status',
      placeholder: 'state',
      descripcion: 'Ej: https://elsitioweb.com'
    },
    telefono: {
      label: 'Telephone',
      placeholder: 'enter the full number',
      descripcion: 'including regional and international codes'
    },
    telefonoAlternativo: {
      label: 'Alternative telephone',
      placeholder: 'enter the full number',
      descripcion: 'including regional and international codes'
    },
    telefonoTrabajoActual: {
      label: 'Current work phone',
      placeholder: 'enter the full number',
      descripcion: 'including regional and international codes'
    },
    trabajoActual: {
      label: 'Current job',
      placeholder: '',
    },
    trabajoActualTexto: {
      label: 'Current job',
      placeholder: 'Enter your current job',
    },
    web: {
      label: 'Website',
      placeholder: 'enter full url',
      descripcion: 'Ej: https://elsitioweb.com'
    },
    intereses: {
      label: 'Interests',
      placeholder: 'Select interests',
    },
    nombreCompleto: {
      label: 'Full name',
      placeholder: 'Enter part of the first / last name',
    },
    telegram: {
      label: 'Telegram',
      placeholder: 'Enter your account telegram',
    },
    facebook: {
      label: 'Facebook',
      placeholder: 'Enter your account facebook',
    },
    twitter: {
      label: 'Twitter',
      placeholder: 'Enter your account twitter',
    },
    linkedin: {
      label: 'Linkedin',
      placeholder: 'Enter your account linkedin',
    },
    instagram: {
      label: 'Instagram',
      placeholder: 'Enter your account instagram',
    },
    compartirDatos: {
      label: 'Share my data',
      placeholder: 'You want to share the data',
    },
    password: {
      label: 'Password',
      placeholder: 'Enter your password',
    },
  },
  botones: {
    iniciarProceso: 'Yes, start closing process',
    procesoDeCierre: 'Close scholarship',
    aplicarFiltros: 'Apply filters',
    quitarFiltros: 'Remove filters',
    verDetalle: 'See details',
    ocultarDetalle: 'Hide details',
    recueracion: 'Forgot access password?',
    ingresar: 'Login',
    crearNuevaCuenta: 'Create new account',
    registro: 'request registration',
    registrarse: 'Sign up',
    volver: 'return',
    salir: 'Leave',
    activar: 'Activate',
    recuperar: 'Recover',
    cambiar: 'Change',
    nuevo: 'New',
    eliminar: 'Remove',
    actualizar: 'To update',
    creando: 'Creandor',
    solicitarBeca: 'Make request',
    nuevaSolicitarBeca: 'Apply for scholarship',
    aprobar: 'Approve',
    negar: 'Deny',
    cartaNotificacionUniversidad: 'University letter',
    cartaPermisoTrabajo: 'Work permit',
    cartaInvitacion: 'Download invitation letter',
    cartaVisa: 'Visa letter',
    cancelarBeca: 'Cancel accepted scholarship',
    cancelarSolicitud: 'Cancel scholarship application',
    rechazar: 'Cancel',
    aceptar: 'Accept',
    informacion: 'information',
    cancelar: 'Cancel',
    meComprometo: 'Yes i promise',
    preseleccionar: 'Pre-select',
    quitarPreseleccion: 'Remove preselection',
    guardar: 'Save'
  },
  menu: {
    inicio: 'Homepage',
    solicitudes: 'Requests',
    usuarios: 'Users',
    configuraciones: 'Configurations',
    roles: 'Roles',
    cerrar: 'Sign off',
    eventos: 'Events',
    agenda: 'Schedule',
    asistentes: 'Assistants',
    asistente: 'Assistant',
    informacion: 'Information',
    sesiones: 'Sessions',
    disertantes: 'Speakers',
    disertante: 'Speaker',
    ayuda: 'Help',
    volver: 'Go back',
    iniciarSesion: 'Log in',
    programa: 'Program',
    miAgenda: 'My agenda',
    miPrefil: 'My profile',
    menu: 'Menú',
  },
  loading: {
    validando: 'Validating',
    iniciandoPanelDeControl: 'Loading control panel',
    completandoRegistro: 'completing the registration',
    activando: 'Activating user',
    primerInicio: 'Logging in for the first time',
    solicitandoRecuperacion: 'Requesting Recovery',
    completandoRecuperacion: 'Sending recovery emails',
    cambiandoClave: 'Changing password',
    negandoSolicitud: 'Denying request',
    aprobandoSolicitud: 'Approving request',
    preseleccionandoSolicitud: 'Pre-selecting request',
    cargandoEventos: 'Loading events',
    cargandoEstadoBeca: 'Loading status information',
    cargandoProfile: 'Loading profile',
    actualizandoPerfil: 'Updating profile',
  },
  errores: {
    inesperado: {
      titulo: 'Unexpected error',
      mensaje: 'Contact the administrator'
    },
  },
  validaciones: {
    seleccionObligatoria: 'Select at least one option'
  },
  tablas: {
    columnas: {
      estado: 'State',
      genero: 'Gender',
      nombreCompleto: 'Full name',
      edad: 'Age',
      email: 'E-mail',
      profesion: 'Profession',
      sector: 'Sector',
      trabajoActual: 'Current job',
      paisResidencia: 'country',
      detalles: 'Details',
    },
    sinRegistros: 'There are no records to display',
    loading: 'Loading data'
  },
  solicitudesBeca: {
    virtual: {
      titulo: 'Scholarship application form',
      subtitulo: '',
      parrafo: 'The 12th consecutive edition of the <strong> South School on Internet Governance </strong> and the <strong> 4th edition of ARGENSIG the Argentine School of Internet Governance </strong> will be organized jointly and virtually between <strong> the on October 5 and 9, 2020 </strong>.'
    }
  },
  modales: {
    aceptaBeca: {
      titulo: 'Commitment',
      texto: 'I commit myself to attend all the activities that are part of the SSIG 2021 program, actively participating both in the self-assisted course, as well as during the week of synchronous activities'
    },
    aprobarBeca:{
      titulo: 'Confirmation',
      texto: 'Are you sure you want to approve the application?'
    },
    negarBeca:{
      titulo: 'Confirmation',
      texto: 'Are you sure you want to deny the request?'
    },
    preseleccionarSolicitud:{
      titulo: 'Pre-select request',
      texto: 'The application will be preselected. It does not imply approval of it, and you can remove the preselection if you wish'
    },
    cambiarIdioma:{
      titulo: 'Change language',
      texto: 'You will lose the information on the current page'
    }
  },
  respuestas: {
    becaAceptada: 'Congratulations your scholarship is confirmed',
    becaAceptadaSubtitlo: 'You will receive more details about the event soon',
    becaRechazada: 'Her scholarship application was rejected',
    becaCancelada: 'Your scholarship application was canceled',
  },
  solicitudesEstado: {
    pendiente: 'Pending approval',
    aprobado: 'Approved',
    negado: 'Denied',
    aceptado: 'Accepted',
    rechazado: 'Rejected',
    cancelacion: 'Cancellation',
  },
  agenda:{
    sinEventos: "There are no events on your agenda"
  },
  gtm: {
    informacion: 'Información',
    informacionGtm: 'Los eventos se encuentran expresados en hora local de su dispositivo',
    zonaHorariaActual: 'Usted se enuentra en la zona horaria: GTM'
  },
  disertantes:{
    sinDisertantes: "No hay disertantes actualmente"
  },
}

export default en
