

import { defineComponent } from "vue";
import { IonButton, IonIcon } from '@ionic/vue';
import { homeOutline } from 'ionicons/icons';
import usePushLink from "@/composables/usePushLink";

export default defineComponent({
  name: 'TheHomeButtom',
  components: {
    IonButton,
    IonIcon
  },
  setup() {
    const { toBack } = usePushLink()

    return {
      homeOutline,
      pushTo: toBack
    }

  }

})
