<template>
  <ion-item>
    <ion-label class="ion-text-uppercase">
      {{ t(label) }}
    </ion-label>
    <ion-checkbox
        slot="start"
        v-model="field"
        :disabled="disabled"
    />
  </ion-item>
</template>

<script lang="ts">
import { IonLabel, IonItem, IonCheckbox } from '@ionic/vue'
import { defineRule, useField } from 'vee-validate'
import { defineComponent, getCurrentInstance, watch } from 'vue'
import { required } from '@vee-validate/rules'
import { useI18n } from "vue-i18n";
import { ComponentInternalInstance } from "@vue/runtime-core";

defineRule('required', required)

export default defineComponent({
  components: {
    IonLabel,
    IonCheckbox,
    IonItem,
  },
  props: {
    modelValue: {
      type: Boolean,
    },
    label: {
      type: String,
      default: '--',
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const instance = getCurrentInstance() as ComponentInternalInstance;
    const { value: field } = useField(
        'input-' + (instance) ? instance.uid.toString() : '0',
        { required: false },
        {
          initialValue: props.modelValue,
          label: t(props.label)
        },
    )

    watch(field, (val) => emit('update:modelValue', val))

    return {
      t,
      field,
    }
  },
})
</script>