
import {
  IonContent,
  IonHeader,
  IonItem,
  IonSearchbar,
  IonList,
  IonIcon,
  IonToolbar,
  IonButtons,
  IonButton,
} from '@ionic/vue'
import { arrowBack } from 'ionicons/icons'
import { defineComponent, ref } from 'vue'
import { SelectedOption } from '@/interfaces/SelectedOption'
import ModalSelect from '@/composables/ModalSelect'
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: 'BaseModalSelect',
  components: {
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonSearchbar,
    IonIcon,
    IonToolbar,
    IonButtons,
    IonButton,
  },
  props: {
    title: { type: String, default: 'Seleccionar una opción' },
    list: Object as () => SelectedOption[],
    selected: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const { t } = useI18n()
    const search = ref<string>()
    const { closeModal, selectOption, filtererList } = ModalSelect(
      props.list,
      search,
    )
    return {
      t,
      filtererList,
      search,
      arrowBack,
      closeModal,
      selectOption,
    }
  },
})
