
import {
  IonContent,
  IonGrid,
  IonPage,
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { calendar, personCircle } from 'ionicons/icons';
import UsePushLink from "@/composables/usePushLink";
import AppTabsBarLang from "@/components/TheTabsBarLang.vue";
import TheHeaderLarge from "@/components/TheHeaderLarge.vue";
import TheButtomNav from "@/components/Buttons/TheButtomNav.vue";
import useLoadEvent from "@/composables/useLoadEvent";
import TheTabsBar from "@/components/TheTabsBar.vue";

export default defineComponent({
  name: 'Home',
  components: {
    TheButtomNav,
    TheHeaderLarge,
    TheTabsBar,
    AppTabsBarLang,
    IonContent,
    IonGrid,
    IonPage,
  },
  props: {
    tabs: Array
  },
  setup() {
    const { toPush, getUrl } = UsePushLink()

    useLoadEvent(4)
    
    return {
      toPush,
      getUrl,
      calendar,
      personCircle
    }
  }

});
