import axios from '@/plugins/axios'
import { loginService } from "@/services/login.service";

const actions = {
  async login({ commit }, data) {
    try {
      const datosUsuario = await loginService.ingresar(data)
      commit('SET_USER', datosUsuario)
      return true
    }catch (e) {
      return false
    }
  },
  async logOut({ commit }) {
    try {
      await axios.post('cerrar', null, { data: { noCapturar: true, noCapturarError: true } })
      return true
    }catch (e) {
      return false
    } finally {
      commit('SET_INITIAL_STATE')
      window.location.href = ''
    }
  }
}


export default actions