import { AxiosInstance } from 'axios'
import axiosProd from './axios-prod';
import axiosDev from './axios-dev';
import axiosTest from './axios-test';
import axiosStaging from './axios-staging';
import { alertController } from "@ionic/vue";

const enviroment = process.env.NODE_ENV || 'production';

let instance: AxiosInstance;
let debug = false;

// Todos los 2**
async function respuestaValida(response) {
  const alerted = await alertController
    .create({
      buttons: [
        {
          text: 'ok',
        },
      ],
    });

  if ( debug ) {
    console.log(response)
  }

  const configData = (typeof response.config.data !== 'undefined' && typeof response.config.data === 'string') ? JSON.parse(response.config.data) : {}

  if ( configData.noCapturar || typeof response.config.headers['X-NO-CAPTURE'] !== 'undefined') {
    return response
  }

  const datos = response.data

  if ( typeof datos.mensaje !== 'undefined' ) {
    switch ( response.status ) {
      case 200:
        alerted.header = datos.mensaje
        break;
      case 201:
        alerted.header = datos.mensaje
        break;
    }
  }

  await alerted.present();
  return response
}

// Todos los 3** 4** 5**
async function respuestaInvalida(error) {

  const alerted = await alertController
    .create({
      buttons: [
        {
          text: 'ok',
        },
      ],
    });

  if ( debug ) {
    console.log(error)
  }

  if ( typeof error.response === 'undefined' ) {
    alerted.header = 'El servidor no responde, intente nuevamente'
    await alerted.present();
    return Promise.reject(error)
  }

  let msj;
  const respuesta = error.response
  const datos = respuesta.data
  const configData = (typeof error.config.data !== 'undefined') ? JSON.parse(error.config.data) : {}

  if (
    typeof configData.noCapturarError !== 'undefined' && configData.noCapturarError
  ) {
    return Promise.reject(error)
  }

  if ( datos ) {

    msj = (typeof datos.mensaje !== 'undefined') ? datos.mensaje : datos.message

    if ( datos.codigo && String(datos.codigo).length > 3 ) {
      return Promise.reject(error)
    }

    switch ( respuesta.status ) {
      case 401:
        alerted.header = msj
        break;
      case 404:
        alerted.header = msj || respuesta.statusText
        await alerted.present();
        break;
      case 410:
        alerted.header = datos.mensaje
        break;
      case 422:
        alerted.header = msj
        console.log(respuesta.status + ': ')
        break;
      default:
        alerted.header = msj
        console.log(respuesta.status + ': ' + respuesta.statusText)
        break;
    }
  } else {
    msj = respuesta.message
    switch ( respuesta.status ) {
      default:
        alerted.header = msj
        console.log(respuesta.status + ': ' + respuesta.statusText)
        break;
    }
  }
  await alerted.present();
  return Promise.reject(error)
}

switch ( enviroment ) {
  case 'development':
    // consumo json public/api
    // no autentico
    // no agrego cabeceras de token
    debug = true;
    instance = axiosStaging;
    break;
  case 'testing':
    // consumo json public/api
    // no autentico
    // no agrego cabeceras de token
    debug = true;
    instance = axiosTest;
    break;
  default:
    // consumo
    //  autentico
    //  agrego cabeceras de token
    if (location.hostname === 'app.mundoconectado.org') {
      instance = axiosProd
    }else{
      instance = axiosStaging
    }

    break;
}

if(debug){
  console.log(enviroment);
}

instance.interceptors.response.use(respuestaValida, respuestaInvalida)

export default instance
