

import { defineComponent } from "vue";
import {
  IonIcon,
  IonLabel,
  IonTabs,
  IonTabBar,
  IonTabButton, IonRouterOutlet,
} from '@ionic/vue';
import {
  gridOutline,
  arrowBackOutline,
  homeOutline,
  ribbonOutline,
  calendarOutline,
  personCircleOutline
} from 'ionicons/icons';
import UsePushLink from "@/composables/usePushLink";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: 'TheTabsBar',
  components: {
    IonIcon,
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonRouterOutlet
  },
  props: {
    tabs: Array
  },
  setup() {

    const { params } = useRoute()
    const { toPush, toBack, getUrl } = UsePushLink()
    const { t } = useI18n()

    return {
      t,
      getUrl,
      toPush,
      toBack,
      gridOutline,
      arrowBackOutline,
      homeOutline,
      ribbonOutline,
      calendarOutline,
      personCircleOutline,
      params
    }
  }

})
