<template>
  <ion-tabs v-if="tabs">
    <ion-router-outlet/>
    <ion-tab-bar slot="bottom">
      <ion-tab-button v-if="tabs.includes('Back')" @click="toBack">
        <ion-icon :icon="arrowBackOutline"></ion-icon>
        <ion-label>{{ t('menu.volver') }}</ion-label>
      </ion-tab-button>

      <ion-tab-button v-if="tabs.includes('Home')" tab="Home" @click="toPush('Home')">
        <ion-icon :icon="homeOutline"></ion-icon>
        <ion-label>{{ t('menu.inicio') }}</ion-label>
      </ion-tab-button>

    </ion-tab-bar>
  </ion-tabs>
</template>

<script lang="ts">

import { defineComponent } from "vue";
import {
  IonIcon,
  IonLabel,
  IonTabs,
  IonTabBar,
  IonTabButton, IonRouterOutlet,
} from '@ionic/vue';
import {
  gridOutline,
  arrowBackOutline,
  homeOutline,
  ribbonOutline,
  calendarOutline,
  personCircleOutline
} from 'ionicons/icons';
import UsePushLink from "@/composables/usePushLink";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: 'TheTabsBar',
  components: {
    IonIcon,
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonRouterOutlet
  },
  props: {
    tabs: Array
  },
  setup() {

    const { params } = useRoute()
    const { toPush, toBack, getUrl } = UsePushLink()
    const { t } = useI18n()

    return {
      t,
      getUrl,
      toPush,
      toBack,
      gridOutline,
      arrowBackOutline,
      homeOutline,
      ribbonOutline,
      calendarOutline,
      personCircleOutline,
      params
    }
  }

})
</script>