const es = {

  derechos: 'Todos los derechos reservados',
  idiomas: {
    veridiomas: 'Cambiar idioma',
    es: 'Español',
    en: 'Ingles'
  },
  textos: {
    titulos: {
      activamenteInvolucradoEvento: 'Activamente Involucrado Evento',
      direccion: 'Dirección postal',
      direccionTrbajoActual: 'Dirección trabajo actual',
      solicitudRegistro: 'Solicitud de registro',
      registroCompletado: 'Confirmación de e-mail requerida',
      activacionUsuario: 'Activación de usuario',
      definirClave: 'Definir una cotraseña',
      solicitudCambioContrasena: 'Solicitar cambio de contraseña',
      solicitudCambioContrasenaCompletada: 'Solicitud de cambio de contrseña',
      formularioCambioClave: 'Cambio de cotraseña',
      definirNuevaClave: 'Nueva Contraseña',
      abmUsuarios: 'Usuarios',
      abmUsuariosListar: 'Listado',
      informacionPersonal: 'Información personal',
      datosDeContacto: 'Datos de contacto',
      redesSociales: 'Redes Sociales',
      informacionLaboral: 'Experiencia profesional ',
      interesesEnElEvento: 'Grupo de intereses',
      otrosIntereses: 'Otros intereses',
      sector: 'Sector',
      sectoresOtros: 'Otro sector',
      confirmacionsolicitud: 'Su solicitud de beca fue recibida correctamente.',
      leaAtentamente: 'Lea atentamente la siguiente información',
      importante: 'Importante',
      notaImportanteSolicitud: 'Por la presente, usted otorga autorización a la CCAT LAT para hacer mención de su participación en el evento, con el fin de promoverlo a través de materiales promocionales (físicos y digitales), tales como anuncios en periódicos, informes, sitios web, redes sociales y otros medios apropiados de publicación en formato impreso, audio, fotografía, video, en persona o virtual. Si por razones personales o profesionales desea limitar la exposición pública de su nombre o imagen, debe notificar a las entidades organizadoras por adelantado para cumplir con la solicitud a info@gobernanzainternet.org. De igual forma, usted otorga autorización a la los organizadores para recopilar y compartir su nombre, dirección o dirección de correo electrónico con otros socios y co-organizadores.',
      cancelacionBeca: 'Cancelación de beca',
      cartaInvitacion: 'Carta de invitación',
      informacionDeBeca: 'Información de beca',
      tipoBeca: 'Tipo de beca',
      estadoSolicitud: 'Su solicitud fué recibida y se encuentra en estado',
      memorandumEntendimiento: 'Memorandum de entendimiento',
      compartirDatos: 'Compartir datos'
    },
    subtitulos: {
      solicitudRegistro: 'Completado el registro podrá verificar su email y establecer una contraseña',
      activacionUsuario: 'Activación de usuario',
      registroCompletado: 'Hemos enviado un email, para completar el proceso verifique su casilla correo electrónico incluyendo  la carpeta de spam.',
      abmUsuariosEditar: 'Editar',
      confirmacionsolicitud: 'Pronto le informaremos si se le otorgará la beca correspondiente.',
    },
    parrafos: {
      solicitudRegistro: 'Completado el registro podrá verificar su email y establecer una contraseña',
      activacionUsuario: 'Para finalizar la activación del usuario deberá establecer una nueva contraseña',
      solicitudCambioContrasena: 'Solicitar cambio de contraseña',
      cancelacionBeca: 'Si por algún motivo usted desea realizar la cancelación de la beca. Haga click en el siguiente botón',
      cartaInvitacion: 'Puede utilizar el siguiente documento para presentarlo en su lugar de trabajo, institución educativa o ante cualquier organismo pertinente; que precise ser informado de que su beca fue aprobada, ya sea por ejemplo para otorgarle autorización en caso de aceptar la beca y/o para justificar ausentismo',
      limiteAceptacion: 'Tiene tiempo hasta el dia [solicitud.vencimientoAprobacion] para <strong>informar su decisión</strong>, de lo contrario la beca se rechazará automaticamente',
      memorandumEntendimiento: 'En caso de aceptar las condiciones del memorandum de entendimiento. Complete el siguiente campo con el email de registro y luego haga click en <strong>"Aceptar"</strong> caso contrario haga click en <strong>"Rechazar"</strong>'
    },
    preposiciones: {
      del: 'Del',
      al: 'al'
    },
    generales: {
      estado: 'Estado',
      estadoAceptandoSolicitud: 'Aceptando solicitudes',
      estadoCerrado: 'Cerrada',
      limiteDeAceptacion: 'Fecha limite de aceptacion de solicitudes',
      fechaAsignacion: 'Fecha de asignación',
      quedan: 'Quedan',
      diasRestantes: 'días para el limite de asignación',
      trabajoActualResponsabilidad: 'Responsabilidad',
      eventosDisponibles: 'Eventos disponibles'
    }
  },
  formularios: {
    activamenteInvolucrado: {
      label: '¿Esta activamente involucrado en espacios de participación de Gobernanza de Internet como ITU, ICANN, LACNIC, IETF, IGF, ISOC, LACigf, LACTLD u otros?',
      placeholder: '',
      descripcion: 'Tener esta experiencia no es requisito para aplicar a la beca. Si la respuesta es si, díganos cuáles y de qué manera esta involucrado.'
    },
    apellido: {
      label: 'Apellidos',
      placeholder: 'Apellido completo'
    },
    parteApellido: {
      label: 'Nombre',
      placeholder: 'Ingrese parte del apellido'
    },
    celular: {
      label: 'Celular',
      placeholder: 'Numero de celular'
    },
    ciudad: {
      label: 'Ciudad',
      placeholder: 'Ingrese la ciudad'
    },
    ciudadResidencia: {
      label: 'Ciudad de Residencia',
      placeholder: 'Ingrese la ciudad'
    },
    codigo: {
      label: 'Código',
      placeholder: 'Ingrese el código'
    },
    codigoTipodocumento: {
      label: 'Código tipo documento',
      placeholder: 'Ingrese el código'
    },
    codigoPais: {
      label: 'Código del país',
      placeholder: 'Ingrese el código'
    },
    cuit: {
      label: 'CUIT',
      placeholder: 'Número de CUIT'
    },
    descripcion: {
      label: 'Descripcion',
      placeholder: 'Ingresar aqui su descripción'
    },
    calle: {
      label: 'Calle',
      placeholder: 'Ingrese su calle'
    },
    codigoPostal: {
      label: 'Código postal',
      placeholder: 'Ingrese el codigo postal'
    },
    contrasena: {
      label: 'Contraseña',
      placeholder: 'Ingrese su contraseña'
    },
    contrasenaconfirmacion: {
      label: 'Confirmar contraseña',
      placeholder: 'Reingrese su contraseña'
    },
    departamento: {
      label: 'Departamento',
      placeholder: 'Ingrese su departamento'
    },
    interes: {
      label: '¿Qué grupo de interés lo representa mejor?',
      placeholder: ''
    },
    interesOtros: {
      label: '¿Qué otro interes lo representa mejor?',
      placeholder: ''
    },
    localidad: {
      label: 'Localidad',
      placeholder: 'Seleccione una opción'
    },
    numeroDePuerta: {
      label: 'Puerta',
      placeholder: ''
    },
    piso: {
      label: 'Piso',
      placeholder: 'Ingrese el piso'
    },
    directorioS3: {
      label: 'Directorio S3',
      placeholder: 'Path de S3'
    },
    doumentoNumero: {
      label: 'Número de documento',
      placeholder: 'Ingrese su número'
    },
    documentoTipo: {
      label: 'Tipo de documento',
      placeholder: 'Seleccione una opción'
    },
    edad: {
      label: 'Edad',
      placeholder: 'Ingrese una edad',
    },
    edadDesde: {
      label: 'Edad',
      placeholder: 'desde',
    },
    edadHasta: {
      label: 'Edad',
      placeholder: 'hasta',
    },
    email: {
      label: 'Correo electrónico',
      placeholder: 'Ingresar su correo',
    },
    estado: {
      label: 'Estado',
      placeholder: 'Selecionar una opción',
    },
    fecha: {
      label: 'Fecha',
      placeholder: 'Seleccione una feha',
    },
    fechaNacimiento: {
      label: 'Fecha de nacimiento',
      placeholder: 'seleccione una fecha'
    },
    fechaFin: {
      label: 'Fecha fin',
      placeholder: 'seleccione una fecha'
    },
    fehaInicio: {
      label: 'Fecha inicio',
      placeholder: 'seleccione una fecha'
    },
    genero: {
      label: 'Genero',
      placeholder: 'Seleccione una opción'
    },
    horaFin: {
      label: 'Hora de finalización',
      placeholder: 'seleccione hora de finaliación'
    },
    horaInicio: {
      label: 'Hora de inicio',
      placeholder: 'seleccione hora de inicio'
    },
    idioma: {
      label: 'Idioma preferido',
      placeholder: 'Seleccionar idioma',
    },
    limite: {
      label: 'Limite de registros',
      placeholder: 'Seleccione limite de registros',
      opciones: 'Mostrar '
    },
    nivel: {
      label: 'Nivel',
      placeholder: 'Seleccione un nivel'
    },
    nombre: {
      label: 'Nombres',
      placeholder: 'Nombre completo'
    },
    nombreEvento: {
      label: 'Nombres evento',
      placeholder: 'Nombre evento'
    },
    parteNombre: {
      label: 'Nombre',
      placeholder: 'Ingrese parte del nombre'
    },
    pais: {
      label: 'País',
      placeholder: 'Ingrese el país'
    },
    paisResidencia: {
      label: 'País de residencia',
      placeholder: 'Ingrese su residencia'
    },
    preseleccionados: {
      label: 'Mostrar solo preseleccionados',
      placeholder: 'Selecciona una opción'

    },
    profesion: {
      label: 'Profesión',
      placeholder: 'Ingrese una profesión',
    },
    primeraReferencia: {
      label: 'Primera referencia',
      placeholder: '',
      descripcion: 'Información completa: nombre, dirección postal, email y teléfono'
    },
    profesionPrevia: {
      label: 'Experiencia profesional anterior',
      placeholder: ''
    },
    provincia: {
      label: 'Provincia',
      placeholder: 'seleccione una opción'
    },
    redesSociales: {
      placeholder: 'Ingresar url del perfil',
    },
    responsabilidadesTrabajoActual: {
      label: 'Explique por favor brevemente cuáles son sus responsabilidades',
      placeholder: 'Ingrese la descripcion',
    },
    rol: {
      label: 'Rol',
      placeholder: 'seleccione una opción'
    },
    sector: {
      label: 'Sector',
      placeholder: 'Permite multiples selecciones',
    },
    segundaReferencia: {
      label: 'Segunda referencia',
      placeholder: '',
      descripcion: 'Información completa: nombre, dirección postal, email y teléfono'
    },
    solicitudEstado: {
      label: 'Estado de solicitud',
      placeholder: 'Estados',
      descripcion: 'Ej: https://elsitioweb.com'
    },
    telefono: {
      label: 'Teléfono',
      placeholder: 'ingresar el numero completo',
      descripcion: 'incluyendo códigos regionales e internacionales'
    },
    telefonoAlternativo: {
      label: 'Teléfono Alternativo',
      placeholder: 'ingresar el numero completo',
      descripcion: 'incluyendo códigos regionales e internacionales'
    },
    telefonoTrabajoActual: {
      label: 'Teléfono del trabajo actual',
      placeholder: 'ingresar el numero completo',
      descripcion: 'incluyendo códigos regionales e internacionales'
    },
    trabajoActual: {
      label: 'Trabajo actual',
      placeholder: 'Select an option',
    },
    trabajoActualTexto: {
      label: 'Trabajo actual',
      placeholder: 'Ingrese su actual trabajo',
    },
    web: {
      label: 'Sitio web',
      placeholder: 'ingresar url completa',
      descripcion: 'Ej: https://elsitioweb.com'
    },
    intereses: {
      label: 'Intereses',
      placeholder: 'Seleccione intereses',
    },
    nombreCompleto: {
      label: 'Nombre completo',
      placeholder: 'Ingrese parte del nombre/apellido',
    },
    telegram: {
      label: 'Telegram',
      placeholder: 'Ingrese su cuenta de telegram',
    },
    facebook: {
      label: 'Facebook',
      placeholder: 'Ingrese su cuenta de facebook',
    },
    twitter: {
      label: 'Twitter',
      placeholder: 'Ingrese su cuenta de twitter',
    },
    linkedin: {
      label: 'Linkedin',
      placeholder: 'Ingrese su cuenta de linkedin',
    },
    instagram: {
      label: 'Instagram',
      placeholder: 'Ingrese su cuenta de instagram',
    },
    compartirDatos: {
      label: 'Compartir mis datos',
      placeholder: 'Quiere compartir los datos',
    },
    password: {
      label: 'Contraseña',
      placeholder: 'Ingrese su contraseña',
    },
  },
  botones: {
    iniciarProceso: 'Si, iniciar proceso de cierre',
    procesoDeCierre: 'Cerrar beca',
    aplicarFiltros: 'Aplicar filtros',
    quitarFiltros: 'Quitar filtros',
    verDetalle: 'Ver detalles',
    ocultarDetalle: 'Ocultar detalles',
    recueracion: '¿Olvidó la contraseña de acceso?',
    ingresar: 'Ingresar',
    crearNuevaCuenta: 'Crear nueva cuenta',
    registro: 'solicitar registro',
    registrarse: 'Registrarse',
    volver: 'Volver',
    salir: 'Salir',
    activar: 'Activar',
    recuperar: 'Recuperar',
    cambiar: 'Cambiar',
    nuevo: 'Nuevo',
    eliminar: 'Eliminar',
    actualizar: 'Actualizar',
    creando: 'Creandor',
    solicitarBeca: 'Realizar solicitud',
    nuevaSolicitarBeca: 'Solicitar beca',
    aprobar: 'Aprobar',
    negar: 'Negar',
    cartaNotificacionUniversidad: 'Carta de universidad',
    cartaPermisoTrabajo: 'Permiso de trabajo',
    cartaInvitacion: 'Descargar carta invitación',
    cartaVisa: 'Carta visa',
    cancelarBeca: 'Cancelar beca aceptada',
    cancelarSolicitud: 'Cancelar solicitud de beca',
    rechazar: 'Rechazar',
    aceptar: 'Aceptar',
    informacion: 'Información',
    cancelar: 'Cancelar',
    meComprometo: 'Si, me comprometo',
    preseleccionar: 'Preseleccionar',
    quitarPreseleccion: 'Quitar preselección',
    guardar: 'Guardar'
  },
  menu: {
    inicio: 'Pagina principal',
    solicitudes: 'Solicitudes',
    usuarios: 'Usuarios',
    configuraciones: 'Configuraciones',
    roles: 'Roles',
    cerrar: 'Cerrar sesión',
    eventos: 'Eventos',
    agenda: 'Agenda',
    asistentes: 'Asistentes',
    asistente: 'Asistente',
    informacion: 'Informacion',
    sesiones: 'Sesiones',
    disertantes: 'Disertantes',
    disertante: 'Disertante',
    ayuda: 'Ayuda',
    volver: 'Volver',
    iniciarSesion: 'Iniciar sesión',
    programa: 'Programa',
    miAgenda: 'Mi agenda',
    miPrefil: 'Mi perfil',
    menu: 'Menú',
  },
  loading: {
    validando: 'Validando',
    iniciandoPanelDeControl: 'Cargando panel de control',
    completandoRegistro: 'completando el registro',
    activando: 'Activando usuario',
    primerInicio: 'Ingresando por primera vez',
    solicitandoRecuperacion: 'Solicitando Recuperación',
    completandoRecuperacion: 'Enviando email de recuperación',
    cambiandoClave: 'Cambiando clave',
    negandoSolicitud: 'Negando solicitud',
    aprobandoSolicitud: 'Aprobando solicitud',
    preseleccionandoSolicitud: 'Preselecccionando solicitud',
    cargandoEventos: 'Cargando eventos',
    cargandoEstadoBeca: 'Cargando Informacion de estados',
    cargandoProfile: 'Cargando perfil',
    actualizandoPerfil: 'Actualizando perfil',
  },
  errores: {
    inesperado: {
      titulo: 'Error inesperado',
      mensaje: 'Pongase en contacto con el administrador'
    },
  },
  validaciones: {
    seleccionObligatoria: 'Selecione al menos una opción'
  },
  tablas: {
    columnas: {
      estado: 'Estado',
      genero: 'Genero',
      nombreCompleto: 'Nombre completo',
      edad: 'Edad',
      email: 'Email',
      profesion: 'Profesión',
      sector: 'Sector',
      trabajoActual: 'Trabajo actual',
      paisResidencia: 'País',
      detalles: 'Detalles',
    },
    sinRegistros: 'No hay registros para mostrar',
    loading: 'Cargando datos'
  },
  solicitudesBeca: {
    virtual: {
      titulo: 'Formulario para solicitud de beca',
      subtitulo: '',
      parrafo: 'La 12va edición consecutiva de la <strong>South School on Internet Governance</strong> y la <strong>4ta edición de ARGENSIG la Escuela Argentina de Gobernanza de Internet</strong> se organizarán en forma conjunta y virtual entre <strong>los días 5 y 9 de octubre de 2020</strong>.'
    }
  },
  modales: {
    aceptaBeca: {
      titulo: 'Compromiso',
      texto: 'Me comprometo a asistir a todas las actividades que formen parte del programa de la SSIG 2021, participando activamente tanto en el curso auto asistido, así como durante la semana de actividades sincrónicas.'
    },
    aprobarBeca: {
      titulo: 'Confirmación',
      texto: '¿Esta seguro que quiere aprobar la solicitud?'
    },
    negarBeca: {
      titulo: 'Confirmación',
      texto: '¿Esta seguro que quiere negar la solicitud?'
    },
    preseleccionarSolicitud: {
      titulo: 'Preseleccionar solicitud',
      texto: 'La solicitud quedará preseleccionada. No implica aprobación de la misma, y prodra quitar la preseleccion si lo desea'
    },
    cambiarIdioma: {
      titulo: 'Cambiar de idioma',
      texto: 'Perderá la información de la página actual'
    }
  },
  respuestas: {
    becaAceptada: 'Felicitaciones su beca esta confirmada',
    becaAceptadaSubtitlo: 'Pronto recibirá mas detalles sobre el evento',
    becaRechazada: 'Su solicitud de beca fue rechazada',
    becaCancelada: 'Su solicitud de beca fue cancelada',
  },
  solicitudesEstado: {
    pendiente: 'Pendiente de aprobación',
    aprobado: 'Aprobado',
    negado: 'Negado',
    aceptado: 'Aceptado',
    rechazado: 'Rechazado',
    cancelacion: 'Cancelación',
  },
  agenda:{
    sinEventos: "No hay eventos en su agenda"
  },
  gtm: {
    informacion: 'Información',
    informacionGtm: 'Los eventos se encuentran expresados en hora local de su dispositivo',
    zonaHorariaActual: 'Usted se enuentra en la zona horaria: GTM'
  },
  disertantes:{
    sinDisertantes: "No hay disertantes actualmente"
  },

}

export default es
