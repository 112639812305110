
import { defineComponent } from "vue";
import moment from "moment-timezone";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n()

    return {
      t,
      moment
    }
  }
})
