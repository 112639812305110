

import { defineComponent } from "vue";
import {
  IonFooter,
  IonRow,
  IonCol,
  IonToolbar,
  IonButton
} from '@ionic/vue';
import {
  gridOutline,
  arrowBackOutline,
  homeOutline,
  ribbonOutline,
  calendarOutline,
  personCircleOutline
} from 'ionicons/icons';
import UsePushLink from "@/composables/usePushLink";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default defineComponent({
  name: 'AppTabsBarLang',
  components: {
    IonFooter,
    IonRow,
    IonCol,
    IonToolbar,
    IonButton
  },
  props: {
    tabs: Array
  },
  setup() {
    const { params } = useRoute()
    const { toPush, toBack, getUrl } = UsePushLink()
    const { t } = useI18n()
    const { commit } = useStore()
    const { locale } = useI18n()

    const changeLang = (lang: string) => {
      commit('SET_LOCALE',  lang)
      locale.value = lang
    }

    return {
      t,
      getUrl,
      toPush,
      toBack,
      gridOutline,
      arrowBackOutline,
      homeOutline,
      ribbonOutline,
      calendarOutline,
      personCircleOutline,
      params,
      changeLang
    }
  }

})
