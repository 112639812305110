import services from "@/services/services";
import axios from "@/plugins/axios";

class LoginService extends services {
  ingresar(form: object): Promise<object> {
    const promesa = axios.post('ingresar', form, { data: { noCapturar: true } })
    return promesa.then((respuesta) => {
      return respuesta.data
    })
  }

}

export const loginService = new LoginService()