

import { defineComponent } from "vue";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonGrid,
} from "@ionic/vue";

export default defineComponent({
  name: 'TheCard',
  components: {
    IonGrid,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
  },
  props: {
    category: String,
    title: String,
    subtitle: String,
    description: String,
  }
})
