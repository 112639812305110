
import {
  IonAvatar,
  IonRow,
  IonCol,
  IonHeader,
  IonToolbar, IonButton, loadingController
} from '@ionic/vue'
import { ref, defineComponent, toRefs } from "vue";
import { profielesService } from "@/services/profiles.service";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    IonAvatar,
    IonRow,
    IonCol,
    IonHeader,
    IonToolbar,
    IonButton
  },
  props: {
    name: {
      type: String,
      required: true
    },
    lastName: {
      type: String,
      required: true
    },
    avatar: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n()
    const { avatar } = toRefs(props)
    const avatarLoading = ref<string>(avatar.value)
    const fileInput = ref<HTMLInputElement>()
    const filePendingUpload = ref(false)
    const { commit } = useStore()

    const loadFile = (ev) => {
      const photo = ev.target.files[0];
      const originalUrlImg = URL.createObjectURL(photo)
      const img = new Image()
      img.src = originalUrlImg
      img.onload = () => {
        URL.revokeObjectURL(originalUrlImg)
        const c = document.createElement('canvas');
        const ctx = c.getContext('2d');
        const imgW = img.width
        const imgH = img.height

        c.width = 200
        c.height = (imgH * c.width) /imgW

        if ( !ctx ) {
          return
        }

        ctx.drawImage(img,
            0,
            0,
            img.width,
            img.height,
            0,
            0,
            c.width,
            c.height
        )

        c.toBlob((blob) => {
          avatarLoading.value = URL.createObjectURL(blob)
          filePendingUpload.value = true
        }, 'image/jpeg', 0.95)
      }
    }

    const selectFile = () => {
      if(fileInput.value){
        fileInput.value.click()
      }
    }

    const cancelUpload = ()=>{
      filePendingUpload.value = false
      avatarLoading.value = avatar.value
      fileInput.value = undefined
    }

    const uploadFile = async () =>{
      if(avatarLoading.value){
        const loading = await loadingController
            .create({
              message: t('loading.actualizandoPerfil'),
            });

        try {
          await loading.present();
          const fileUrl = avatarLoading.value
          const blob = await fetch(fileUrl).then(r => r.blob());
          const file = new File([blob], "avatar")
          const urlAvatar = await profielesService.updateImg(file)
          commit('SET_AVATAR', urlAvatar )
          filePendingUpload.value = false
          fileInput.value = undefined
        } finally {
          await loading.dismiss();
        }
      }
    }

    return {
      loadFile,
      avatarLoading,
      uploadFile,
      selectFile,
      fileInput,
      filePendingUpload,
      cancelUpload
    }
  },
})
