<template>
  <ion-item :class="meta.validated && !meta.valid ? 'error' : ''">
    <ion-label
        position="stacked"
        class="ion-text-uppercase"
    >
      {{ t(label) }} {{ required ? "*" : "" }}
    </ion-label>
    <ion-datetime
        v-model="field"
        type="date"
        :placeholder="t(placeholder)"
        :disabled="disabled"
        :required="required"
        display-format=""
        :monthNames="monthNames"
        :monthShortNames="monthNames"
        :cancelText="t('botones.cancelar')"
        :doneText="t('botones.aceptar')"
    />
  </ion-item>
  <div class="ion-text-right">
    <span class="error-text">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script lang="ts">
import { IonLabel, IonItem, IonDatetime } from "@ionic/vue";
import { defineRule, useField } from "vee-validate";
import { computed, defineComponent, watch } from "vue";
import { required } from "@vee-validate/rules";
import { useI18n } from "vue-i18n";

defineRule("required", required);

export default defineComponent({
  components: {
    IonLabel,
    IonItem,
    IonDatetime,
  },
  props: {
    modelValue: {
      type: String,
    },
    label: {
      type: String,
      default: "Fecha",
    },
    placeholder: {
      type: String,
      default: "Ingrese una fecha",
    },
    required: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t, locale } = useI18n()

    const monthNames = computed(() => {
      if ( locale.value === 'es' ) {
        return [
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre'
        ]
      }
      return undefined
    })

    const { value: field, errorMessage, meta } = useField(
        "fecha",
        { required: props.required },
        {
          initialValue: props.modelValue,
          label: t(props.label)
        }
    );

    watch(field, (val) => emit("update:modelValue", val));


    return {
      t,
      field,
      errorMessage,
      meta,
      monthNames,
    };
  },
});
</script>

<style scoped>
ion-label::before {
  color: red;
  font-weight: bold;
}
</style>
