<template>
  <ion-row class="ion-no-padding ion-no-margin">
    <ion-col
      size="4"
      size-sm="2"
    >
      <div class="container-buttom">
        <ion-button
          expand="full"
          fill="clear"
          @click="toPush( 'Days', { eventId: '4'})"
          disabled="true"
        >
          <div class="container-buttom-text">
            <ion-icon :icon="calendarOutline" />
            <h5 class="ion-text-uppercase">
              {{ t('menu.agenda') }}
            </h5>
          </div>
        </ion-button>
      </div>
    </ion-col>
    <ion-col
      size="4"
      size-sm="2"
    >
      <div class="container-buttom">
        <ion-button
          expand="full"
          fill="clear"
          @click="toPush( 'Assistants', { eventId: '4'})"
          disabled="true"
        >
          <div class="container-buttom-text">
            <ion-icon :icon="schoolOutline" />
            <h5 class="ion-text-uppercase">
              {{ t('menu.asistentes') }}
            </h5>
          </div>
        </ion-button>
      </div>
    </ion-col>
    <ion-col
      size="4"
      size-sm="2"
    >
      <div class="container-buttom">
        <ion-button
          expand="full"
          fill="clear"
          @click="toPush( 'Event', { eventId: '4'})"
          disabled="true"
        >
          <div class="container-buttom-text">
            <ion-icon :icon="informationCircleOutline" />
            <h5 class="ion-text-uppercase">
              {{ t('menu.informacion') }}
            </h5>
          </div>
        </ion-button>
      </div>
    </ion-col>
    <ion-col
      size="4"
      size-sm="2"
    >
      <div class="container-buttom">
        <ion-button
          expand="full"
          fill="clear"
          target="_blank"
          :href="session"
          disabled="true"
        >
          <div class="container-buttom-text">
            <ion-icon :icon="videocamOutline" />
            <h5 class="ion-text-uppercase">
              {{ t('menu.sesiones') }}
            </h5>
          </div>
        </ion-button>
      </div>
    </ion-col>
    <ion-col
      size="4"
      size-sm="2"
    >
      <div class="container-buttom">
        <ion-button
          expand="full"
          fill="clear"
          @click="toPush( 'Speakers', { eventId: '4'})"
          disabled="true"
        >
          <div class="container-buttom-text">
            <ion-icon :icon="megaphoneOutline" />
            <h5 class="ion-text-uppercase">
              {{ t('menu.disertantes') }}
            </h5>
          </div>
        </ion-button>
      </div>
    </ion-col>
    <ion-col
      size="4"
      size-sm="2"
    >
      <div class="container-buttom">
        <ion-button
          expand="full"
          fill="clear"
          href="mailto:info+ssig2021@gobernanzainternet.org"
          disabled="true"
        >
          <div class="container-buttom-text">
            <ion-icon :icon="helpOutline" />
            <h5 class="ion-text-uppercase">
              {{ t('menu.ayuda') }}
            </h5>
          </div>
        </ion-button>
      </div>
    </ion-col>
  </ion-row>
</template>
<script lang="ts">
import {
  IonCol,
  IonIcon,
  IonRow,
  IonButton,
} from '@ionic/vue';
import { computed, defineComponent } from "vue";
import {
  calendarOutline,
  helpOutline,
  videocamOutline,
  informationCircleOutline,
  schoolOutline,
  megaphoneOutline
} from 'ionicons/icons';
import UsePushLink from "@/composables/usePushLink";
import { useI18n } from "vue-i18n";
import youtubeLink from "@/model/youtubeLink";

export default defineComponent({
  name: 'ThebuttomNav',
  components: {
    IonRow,
    IonCol,
    IonIcon,
    IonButton,
  },
  setup() {

    const { t, locale } = useI18n()
    const { toPush } = UsePushLink()
    const session = computed(()=>{
      return youtubeLink[locale.value]
    })


    return {
      t,
      calendarOutline,
      helpOutline,
      videocamOutline,
      informationCircleOutline,
      schoolOutline,
      megaphoneOutline,
      toPush,
      session
    }
  }
})
</script>
<style scoped>

.container-buttom {
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative; /* If you want text inside of it */
}

.container-buttom .container-buttom-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-buttom .container-buttom-text h4 {
  margin-top: 5px;
}

.container-buttom .container-buttom-text ion-icon {
  font-size: 2.5em;
  margin: 0.3em 0px;
}


ion-button {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  margin: 0px;
}

.container-buttom .test {
  flex-direction: column;
}
</style>