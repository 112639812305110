import { toastController } from '@ionic/vue';

export default function ToastConnectionStatus() {

  let toast: HTMLIonToastElement | null = null

  const modoOffLine = async () => {
    toast = await toastController
      .create({
        message: 'Modo Offline.',
        color: "danger",
        duration: 2000
      })

    await toast.present()
  }

  const modoOnLine = async () => {
    if ( toast ) {
      await toast.dismiss()
    }

    toast = await toastController
      .create({
        message: 'Modo Online',
        color: "success",
        duration: 2000
      })
    await toast.present()
  }

  return {
    modoOffLine,
    modoOnLine
  }
}