import { ref, onMounted, Ref, computed } from 'vue'
import axios from '@/plugins/axios'
import { SelectedOption, SelectedOptionAutocompletar } from '@/interfaces/SelectedOption'
import professions from '@/model/ProfesionesListado'
import { useI18n } from "vue-i18n";

export default function ProfessionSelector(
  professionId: Ref<number | undefined>,
) {
  const prof = ref<SelectedOption[]>([])
  const { locale } = useI18n()

  const fetchProfession = async () => {
    const data = professions[locale.value]
    prof.value = data.map((list) => {
      return {
        id: list,
        name: list
      }
    })
  }

  const professionCountry = computed(() => {
    return prof.value.find((p) => p.id === professionId.value)
  })

  onMounted(() => {
    fetchProfession()
  })

  return {
    prof,
    professionCountry,
  }
}
