<template>
  <div>
    <small class="ion-margin-vertical">
      ©2019 Mundo conectado - {{ t('derechos') }}
    </small>
  </div>
</template>

<script lang="ts">

import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: 'TheFooter',
  setup(){
    const { t } = useI18n()

    return {
      t
    }
  }
})
</script>