import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';
import mutations from '@/store/mutations';
import actions from '@/store/actions';
import getters from '@/store/getters';
import state from "@/store/state";

const vuexLocal = new VuexPersistence({
    storage: localStorage
})

const store = createStore({
    plugins: [vuexLocal.plugin],
    strict: true,
    state,
    getters,
    mutations,
    actions,
});


export default store