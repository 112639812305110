import { RouteParams, useRouter } from "vue-router";

export default function usePushLink() {

  const router = useRouter()

  const simplePush = async (href: string) => {
    await router.push(href)
  }

  const toPush = async (to: string, params?: RouteParams) => {
    await router.push({ name: to, params })
  }
  const toBack = () => {
    router.back()
  }
  const getUrl = (to: string, params?: RouteParams) => {
    return router.resolve({ name: to, params }).href
  }

  return {
    simplePush,
    getUrl,
    toPush,
    toBack
  }
}