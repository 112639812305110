import { ref, onMounted, Ref, computed } from 'vue'
import axios from '@/plugins/axios'
import { SelectedOption, SelectedOptionAutocompletar } from '@/interfaces/SelectedOption'

export default function CountrySelector(countryId: Ref<number | undefined>) {
  const countries = ref<SelectedOption[]>([])

  const fetchCountries = async () => {
    const { data } = await axios.get<SelectedOptionAutocompletar>('/paises/autocompletar', { data: { noCapturar: true } })
    countries.value = data.listado.map((list) => {
      return {
        id: list.id,
        name: list.nombre
      }
    })
  }

  const selectedCountry = computed(() => {
    return countries.value.find((c) => c.id === countryId.value)
  })

  onMounted(() => {
    fetchCountries()
  })

  return {
    countries,
    selectedCountry,
  }
}
