<template>
  <ion-header class="ion-no-border">
    <ion-toolbar color="primary" class="ion-text-center">
      <template v-if="state.authUser">

        <template v-if="state.event">
          <img slot="start" :src="state.event.logoUrlBlanco"/>
        </template>
        <template v-else>
          <ion-skeleton-text animated class="imgSkeletor"></ion-skeleton-text>
        </template>
        <ion-buttons slot="end">
          <ion-button @click="openMenu()" class="avatar_class">
            <ion-avatar class="md hydrated avatar_class">
              <img
                  :src="state.authUser.avatar? state.authUser.avatar: '/img/avatar.svg'"
              />
            </ion-avatar>
          </ion-button>
        </ion-buttons>
      </template>
      <template v-else>
        <template v-if="state.event">
          <img :src="state.event.logoUrlBlanco"/>
        </template>
        <template v-else>
          <ion-skeleton-text animated class="imgSkeletor"></ion-skeleton-text>
        </template>
      </template>
    </ion-toolbar>
    <template v-if="subseccion || subseccionNoTrans">
      <ion-toolbar class="ion-text-center subseccion">
        <h6 class="ion-text-uppercase ion-margin-end">{{ (subseccionNoTrans) ? subseccionNoTrans : t(subseccion) }}</h6>
      </ion-toolbar>
    </template>
  </ion-header>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import {
  IonHeader,
  IonToolbar,
  IonAvatar,
  IonButton,
  IonButtons,
  menuController,
  IonSkeletonText
} from '@ionic/vue'
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: 'TheHeaderLarge',
  components: {
    IonHeader,
    IonToolbar,
    IonAvatar,
    IonButton,
    IonButtons,
    IonSkeletonText
  },
  props: {
    subseccion: {
      type: String,
      required: false,
    },
    subseccionNoTrans: {
      type: String,
      required: false,
    },
  },
  setup() {
    const { state } = useStore()
    const { t } = useI18n()
    const openMenu = async () => {
      await menuController.open('start')
    }
    return {
      t,
      openMenu,
      state,
    }
  },
})
</script>
<style scoped>
ion-toolbar img {
  max-width: 50vw;
  max-height: 20vh;
  margin: 3vh 0px;
}

.imgSkeletor {
  max-width: 25vw;
  max-height: 25vh;
  margin: 3vh auto;
  text-align: center;
  height: 500px;
}

.avatar_class {
  width: 5rem;
  height: 4rem;
  display: flex;
  align-items: center;
}

ion-toolbar.subseccion {
  --background: #0f0f58;
  --color: #fff;
  --min-height: 3em;
}
</style>
