
import {
  IonAvatar,
  IonContent,
  IonPage, IonText,
} from '@ionic/vue';
import { defineComponent, onMounted, ref } from 'vue';
import TheHeaderLarge from "@/components/TheHeaderLarge.vue";
import { Speaker } from "@/interfaces/Program/Speaker";
import { useRoute } from "vue-router";
import TheList from "@/components/List/TheList.vue";
import { ItemsList } from "@/components/List/ItemList";
import TheCard from "@/components/Card/TheCard.vue";
import { speakerService } from "@/services/speaker.service";
import UsePushLink from "@/composables/usePushLink";
import AppInfoGtm from "@/components/GTM/AppInfoGtm.vue";

export default defineComponent({
  name: 'Speaker',
  components: {
    AppInfoGtm,
    TheCard,
    TheList,
    TheHeaderLarge,
    IonContent,
    IonPage,
    IonAvatar,
    IonText
  },
  setup() {
    const route = useRoute()
    const eventId: string = route.params.eventId.toString()
    const speakerId: string = route.params.speakerId.toString()
    const { getUrl } = UsePushLink()
    const speaker = ref<Speaker>()
    const presentations = ref<ItemsList[]>([])

    const html = '<p>sadsadas</p>'
    onMounted(async () => {
      const speakerData = await speakerService.show(eventId, speakerId)
      speaker.value = speakerData
      if(speakerData.presentaciones){
        presentations.value = speakerData.presentaciones.map((presentation) => {
          return {
            hour: presentation.hora,
            title: presentation.titulo,
            category: presentation.keynote ? 'Keynote' : undefined,
            important: (presentation.keynote),
            link: getUrl('Presentation', { eventId: eventId, presentationId: presentation.id.toString() }),
          }
        })
      }
    })

    return {
      speaker,
      presentations,
      html
    }
  }
});
