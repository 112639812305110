import { modalController } from "@ionic/vue";
import { computed, Ref } from "vue";
import { SelectedOption } from "@/interfaces/SelectedOption";

export default function ModalSelect(list: SelectedOption[] = [], search: Ref<string | undefined>) {

  const closeModal = async (id: number) => {
    await modalController.dismiss(id)
  }

  const selectOption = async (id: number) => {
    await modalController.dismiss(id)
  }

  const filtererList = computed(() => {
    return list.filter((option) => {
        if ( search.value ) {
          return option.name.toLowerCase().includes(search.value.toLowerCase())
        }
        return true
      }
    )
  })

  return {
    closeModal,
    selectOption,
    filtererList
  }

}
