<template>
  <ion-item :class="meta.validated && !meta.valid ? 'error' : ''">
    <ion-label position="stacked">
      {{ label.toUpperCase() }} {{ required ? '*' : '' }}
    </ion-label>
    <ion-textarea
        v-model="field"
        :placeholder="placeholder"
        :disabled="disabled"
        :auto-grow="field.length"
    />
  </ion-item>
  <div class="ion-text-right">
    <span class="error-text">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script lang="ts">
import { IonItem, IonLabel, IonTextarea } from '@ionic/vue'
import { defineComponent, getCurrentInstance, watch } from 'vue'
import { defineRule, useField } from 'vee-validate'
import { required } from '@vee-validate/rules';
import { ComponentInternalInstance } from "@vue/runtime-core";
import { useI18n } from "vue-i18n";

defineRule('required', required);

export default defineComponent({
  components: { IonItem, IonLabel, IonTextarea },
  props: {
    modelValue: {
      type: String,
    },
    label: {
      label: String,
      default: 'Descripción',
    },
    required: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      placeholder: String,
      default: 'Ingrese una descripción',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const instance = getCurrentInstance() as ComponentInternalInstance;
    const { value: field, errorMessage, meta } = useField(
        'input-' + (instance) ? instance.uid.toString() : '0',
        { required: props.required },
        {
          initialValue: props.modelValue,
          label: t(props.label)
        }
    )

    watch(field, (value) => emit('update:modelValue', value))

    return {
      field,
      errorMessage,
      meta
    }
  },
})
</script>