<template>
  <ion-item
    class="item-interactive"
    :class="!selectedCountry ? 'error' : ''"
  >
    <ion-label class="ion-text-uppercase">{{ t(label) }}</ion-label>
    <ion-button @click="openModal">
      {{ selectedCountry ? selectedCountry.name : 'Seleccionar' }}
    </ion-button>
  </ion-item>
</template>

<script lang="ts">
import { IonItem, IonButton, modalController, IonLabel } from '@ionic/vue'
import { defineComponent } from '@vue/runtime-core'
import { toRefs } from 'vue'
import { useI18n } from 'vue-i18n'

import Modal from './BaseModalSelect.vue'
import Country from '@/composables/CountrySelector'

export default defineComponent({
  components: {
    IonItem,
    IonButton,
    IonLabel,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: { type: Boolean, default: true },
    modelValue: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const { modelValue } = toRefs(props)
    const { countries, selectedCountry } = Country(modelValue)

    const openModal = async () => {
      const modal = await modalController.create({
        component: Modal,
        componentProps: {
          title: props.placeholder,
          list: countries.value,
          selected: props.modelValue,
        },
      })

      await modal.present()
      const { data } = await modal.onDidDismiss()
      emit('update:modelValue', data)
    }

    return {
      t,
      openModal,
      selectedCountry,
    }
  },
})
</script>
