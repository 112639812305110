<template>
  <ion-page>
    <ion-content>
      <AppHeaderProfile
          :name="state.authUser.nombre"
          :last-name="state.authUser.apellido"
          :avatar="state.authUser.avatar? state.authUser.avatar: '/img/avatar.svg'"
      />
      <FromProfile class="ion-padding"/>
    </ion-content>
  </ion-page>
</template>
<script lang="ts">
import { defineComponent } from '@vue/runtime-core'
import AppHeaderProfile from '@/components/TheHeaderProfile.vue'
import { IonContent, IonPage } from '@ionic/vue'
import FromProfile from "@/views/User/FromProfile.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: 'Profile',
  components: {
    IonContent,
    IonPage,
    AppHeaderProfile,
    FromProfile
  },
  setup() {
    const { state } = useStore()

    return {
      state
    }
  }
})
</script>
