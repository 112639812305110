
import { defineComponent, watch } from 'vue'
import { defineRule, useField } from 'vee-validate'
import { required } from '@vee-validate/rules'
import InterestRadio from '@/composables/InterestRadio'
import {
  IonLabel,
  IonListHeader,
  IonRadio,
  IonRadioGroup,
  IonItem,
} from '@ionic/vue'
import { useI18n } from "vue-i18n";

defineRule('required', required)

export default defineComponent({
  components: {
    IonLabel,
    IonListHeader,
    IonRadio,
    IonItem,
    IonRadioGroup,
  },
  props: {
    modelValue: {
      type: Number,
    },
    label: {
      type: String,
      default: 'Grupo de interes',
    },
    placeholder: {
      type: String,
      default: 'Seleccione un interes',
    },
    required: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const { interests } = InterestRadio()

    const { value, errorMessage, meta } = useField(
        'radio-button',
        { required: props.required },
        {
          initialValue: props.modelValue,
          label: t(props.label)
        },
    )

    watch(value, () => emit('update:modelValue', value.value))

    return {
      t,
      value,
      meta,
      interests,
      errorMessage,
    }
  },
})
