
import { defineComponent } from 'vue'
import {
  IonHeader,
  IonToolbar,
  IonAvatar,
  IonButton,
  IonButtons,
  menuController,
  IonSkeletonText
} from '@ionic/vue'
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: 'TheHeaderLarge',
  components: {
    IonHeader,
    IonToolbar,
    IonAvatar,
    IonButton,
    IonButtons,
    IonSkeletonText
  },
  props: {
    subseccion: {
      type: String,
      required: false,
    },
    subseccionNoTrans: {
      type: String,
      required: false,
    },
  },
  setup() {
    const { state } = useStore()
    const { t } = useI18n()
    const openMenu = async () => {
      await menuController.open('start')
    }
    return {
      t,
      openMenu,
      state,
    }
  },
})
