
import { defineComponent } from '@vue/runtime-core'
import AppHeaderProfile from '@/components/TheHeaderProfile.vue'
import { IonContent, IonPage } from '@ionic/vue'
import FromProfile from "@/views/User/FromProfile.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: 'Profile',
  components: {
    IonContent,
    IonPage,
    AppHeaderProfile,
    FromProfile
  },
  setup() {
    const { state } = useStore()

    return {
      state
    }
  }
})
