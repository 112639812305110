<template>
  <ion-item :class="meta.validated && !meta.valid ? 'error' : ''">
    <ion-label
        position="stacked"
        class="ion-text-uppercase"
    >
      {{ t(label) }} {{ required ? "*" : "" }}
    </ion-label>
    <ion-input
        v-model="field"
        type="url"
        :placeholder="t(placeholder)"
        :disabled="disabled"
        hint="sadsa"
    />
  </ion-item>
  <div class="ion-text-right">
    <span class="error-text">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script lang="ts">
import { IonLabel, IonInput, IonItem } from "@ionic/vue";
import { defineRule, useField } from "vee-validate";
import { defineComponent, getCurrentInstance, watch } from "vue";
import { required, url } from "@vee-validate/rules";
import { useI18n } from "vue-i18n";
import { ComponentInternalInstance } from "@vue/runtime-core";

defineRule("required", required);
defineRule("url", url);

export default defineComponent({
  components: {
    IonLabel,
    IonInput,
    IonItem,
  },
  props: {
    modelValue: {
      type: String,
    },
    label: {
      type: String,
      default: "formulario.web.label",
    },
    placeholder: {
      type: String,
      default: "formulario.web.placeholder",
    },
    required: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const instance = getCurrentInstance() as ComponentInternalInstance;
    const { value: field, errorMessage, meta } = useField(
        'input-' + (instance) ? instance.uid.toString() : '0',
        { required: props.required, url: true },
        {
          initialValue: props.modelValue,
          label: t(props.label)
        }
    );

    watch(field, (val) => emit("update:modelValue", val));

    return {
      t,
      field,
      errorMessage,
      meta,
    };
  },
});
</script>