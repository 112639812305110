import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Registration from "@/views/Registration.vue";

import Speakers from "@/views/Event/Speakers/Speakers.vue";
import Speaker from "@/views/Event/Speakers/Speaker.vue";
import Assistants from "@/views/Event/Assistants/Assistants.vue";
import Assistant from "@/views/Event/Assistants/Assistant.vue";
import Test from "@/views/Test.vue";
import Profile from "@/views/Profile.vue";
import store from "@/plugins/store";
import Agenda from "@/views/Event/MyAgenda/Agenda.vue";
import { menuController } from "@ionic/vue";

function isAuth(to, from, next) {
  if ( typeof store.state.authUser !== 'undefined' ) {
    return next({ name: 'Home' })
  }
  return next()
}

function requiredAuth(to, from, next) {
  if ( typeof store.state.authUser === 'undefined' ) {
    return next({ name: 'Login' })
  }
  return next()
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: isAuth,
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration,
    beforeEnter: isAuth
  },
  {
    path: '/events/:eventId',
    component: () => import('@/layout/Dashboard.vue'),
    props: {
      tabs: [ 'Back', 'Home' ]
    },
    children: [
      {
        path: '',
        name: 'Event',
        component: () => import('@/views/Event/Event.vue'),
      },
      {
        path: 'myAgenda',
        name: 'Agenda',
        component: Agenda,
        beforeEnter: requiredAuth
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Profile,
      },
      {
        path: 'speakers',
        name: 'Speakers',
        component: Speakers,
        beforeEnter: requiredAuth
      },
      {
        path: 'speakers/:speakerId',
        name: 'Speaker',
        component: Speaker,
        beforeEnter: requiredAuth
      },
      {
        path: 'days',
        name: 'Days',
        component: () => import('@/views/Event/Program/Days.vue'),
        beforeEnter: requiredAuth
      },
      {
        path: ':dayId/presentations',
        name: 'Presentations',
        component: () => import('@/views/Event/Program/Presentations.vue'),
        beforeEnter: requiredAuth
      },
      {
        path: 'assistants',
        name: 'Assistants',
        component: Assistants,
        beforeEnter: requiredAuth
      },
      {
        path: 'assistants/:assistantId',
        name: 'Assistant',
        component: Assistant,
        beforeEnter: requiredAuth
      },
      {
        path: 'presentations/:presentationId',
        name: 'Presentation',
        component: () => import('@/views/Event/Program/Presentation.vue'),
        beforeEnter: requiredAuth
      }
    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  if ( !store.state.event && to.name !== 'Home' ) {
    next({ name: 'Home' })
  }else {
    next()
  }
  await menuController.close('start')

})


export default router
