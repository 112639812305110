<template>
  <Field
    v-slot="{ field, meta, errorMessage }"
    v-model="val"
    name="idiom"
    :rules="{ required: required, oneOf: oneOf }"
    :label="t(label)"
  >
    <ion-item :class="meta.validated && !meta.valid ? 'error' : ''">
      <ion-label class="ion-text-uppercase">
        {{ t(label) }} {{ required ? '*' : '' }}
      </ion-label>
      <ion-select
        v-bind="field"
        :disabled="disabled"
        @ionChange="setSelectedIdioms"
      >
        <ion-select-option
          v-for="i of idioms"
          :key="i.id"
          :value="i.id"
        >
          {{ i.name }}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <div class="ion-text-right">
      <span class="error-text">
        {{ errorMessage }}
      </span>
    </div>
  </Field>
</template>

<script lang="ts">
import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/vue'
import { defineComponent, ref, watch } from 'vue'
import { defineRule, Field } from 'vee-validate'
import { required, one_of as oneOf } from '@vee-validate/rules'

import IdiomRespository from '@/composables/IdiomSelect'
import { useI18n } from "vue-i18n";

defineRule('oneOf', oneOf)
defineRule('required', required)

export default defineComponent({
  components: {
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    Field,
  },
  props: {
    modelValue: {
      type: String,
    },
    label: {
      type: String,
      default: 'Seleccione un idioma',
    },
    placeholder: {
      type: String,
      default: 'Seleccione un idioma',
    },
    required: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const { idioms } = IdiomRespository()
    const val = ref(props.modelValue)
    const oneOf = ref<(number|string)[]>([])

    watch(idioms, () => {
      oneOf.value = Array.from(idioms.value.map((i) => i.id))

    })
    const setSelectedIdioms = (event: CustomEvent) => {
      emit('update:modelValue', event.detail.value)
    }

    return {
      t,
      oneOf,
      val,
      idioms,
      setSelectedIdioms,
    }
  },
})
</script>
