import { useStore } from "vuex";
import { ItemsListPresentation } from "@/components/List/ItemList";

export default function useMyAgenda(eventId: number | string) {
  const { commit, state } = useStore()


  const hasPresentation = (id: number | string) => {
    if ( state.myAgenda && state.myAgenda[eventId] ) {
      const exist = state.myAgenda[eventId].find((presentationStore) => presentationStore.id === id)
      return !!(exist)
    }

    return false;
  }

  const addPresentation = (presentation: ItemsListPresentation) => {
    if ( !hasPresentation(presentation.id) ) {
      commit('ADD_PRESENTATION_TO_MY_AGENDA', { eventId, presentation })
    }
  }

  const removePresentation = (presentationId: string | number) => {
    if ( hasPresentation(presentationId) ) {
      commit('REMOVE_PRESENTATION_TO_MY_AGENDA', { eventId, presentationId })
    }
  }

  const seePresentations = (): ItemsListPresentation[] => {
    if(state.myAgenda && state.myAgenda[eventId] ){
      return state.myAgenda[eventId]
    }
    return []
  }


  return {
    hasPresentation,
    addPresentation,
    removePresentation,
    seePresentations,
  }

}