import { onMounted, ref } from 'vue';
import { SelectedOption } from "@/interfaces/SelectedOption";

export default function IdiomRespository() {
  const idioms = ref<SelectedOption[]>([])

  const fetchIdioms = async () => {
    idioms.value = [
      {
        id: 'es',
        name: 'Español / Spanish',
      },
      {
        id: 'en',
        name: 'Ingles / English',
      }
    ]
  }

  onMounted(fetchIdioms)
  return {
    idioms
  }
}