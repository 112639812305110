<template>
  <ion-page>
    <TheHeaderLarge subseccion="menu.disertantes"/>
    <ion-content fullscreen>
      <template v-if="speakersList.length">
        <TheList :items-list="speakersList"/>
      </template>
      <template v-else>
        <ion-card>
          <ion-card-content class="ion-text-center">
            <h1>{{ t('disertantes.sinDisertantes') }}</h1>
          </ion-card-content>
        </ion-card>
      </template>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonCard,
  IonCardContent,
  IonContent,
  IonPage,
} from '@ionic/vue';
import { defineComponent, onMounted, ref } from 'vue';
import TheHeaderLarge from "@/components/TheHeaderLarge.vue";
import TheList from "@/components/List/TheList.vue";
import { ItemsList } from "@/components/List/ItemList";
import UsePushLink from "@/composables/usePushLink";
import { useRoute } from "vue-router";
import { speakerService } from "@/services/speaker.service";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: 'Speakers',
  components: {
    TheList,
    TheHeaderLarge,
    IonContent,
    IonPage,
    IonCard,
    IonCardContent
  },
  setup() {
    const { t } = useI18n()
    const route = useRoute()
    const eventId = route.params.eventId.toString()
    const { getUrl } = UsePushLink()
    const speakersList = ref<ItemsList[]>([])

    onMounted(async () => {
      const speakersData = await speakerService.list(eventId)
      speakersList.value = speakersData.map((speaker) => {
        return {
          thumb: (speaker.thumb) ? speaker.thumb : 'https://ionicframework.com/docs/demos/api/avatar/avatar.svg',
          title: speaker.nombre,
          link: getUrl('Speaker', { eventId: eventId, speakerId: speaker.id.toString() }),
          subtitle: speaker.cargo
        }
      })
    })


    return {
      t,
      speakersList
    }
  }
});
</script>

<style scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>