import { EventShow } from "@/interfaces/EventShow";

const mutations = {
  SET_LOCALE: function (state, locale) {
    state.locale = locale
  },
  SET_INITIAL_STATE: (state) => {
    delete state.authUser
  },
  SET_USER: function (state, user) {
    state.authUser = user
  },
  SET_EVENT: function (state, event: EventShow) {
    state.event = event
  },
  ADD_PRESENTATION_TO_MY_AGENDA: function (state, { eventId, presentation }) {
    if(!state.myAgenda){
      state.myAgenda = {
        [eventId]: []
      }
    }
    state.myAgenda[eventId].push(presentation)
  },
  REMOVE_PRESENTATION_TO_MY_AGENDA: function (state, { eventId, presentationId }) {

    if(!state.myAgenda && !state.myAgenda[eventId]){
      return
    }

    const index = state.myAgenda[eventId].findIndex((presentationInStore) => presentationInStore.id === presentationId)
    state.myAgenda[eventId].splice(index, 1)
  },
  SET_AVATAR: function (state, url: string) {
    state.authUser.avatar = url
  },
}
export default mutations