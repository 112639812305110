import services from "@/services/services";
import axios from '@/plugins/axios'
import { Assistant } from "@/interfaces/Assistant";

class AssistantsService extends services {

  list(eventoId: string|number, limit = 20, offset= 1): Promise<Assistant[]>  {

    const promesa = axios.get('eventos/' + eventoId + '/asistentes?limite=' + limit + '&sentido=ASC&pagina=' + offset , { data: { noCapturar: true } })

    return promesa.then((respuesta)=>{
      return respuesta.data
    }).catch((e) => {
      console.info(e)
      throw new Error('Error')
    })
  }

  show(eventoId: string|number, speakerId: string|number ): Promise<Assistant>  {

    const promesa = axios.get('eventos/' + eventoId + '/asistentes/' + speakerId, { data: { noCapturar: true } })

    return promesa.then((respuesta)=>{
      return respuesta.data
    }).catch((e) => {
      console.info(e)
      throw new Error('Error')
    })
  }

}

export const assistantsService = new AssistantsService()