import services from "@/services/services";
import axios from '@/plugins/axios'
import { EventShow } from "@/interfaces/EventShow";

class EventService extends services {

  show(eventId: number | string): Promise<EventShow> {

    const promise = axios.get('eventos/' + eventId + '/ver', { data: { noCapturar: true } })

    return promise.then((response) => {
      return response.data
    }).catch((e) => {
      console.info(e)
      throw new Error('Error')
    })
  }
}

export const eventService = new EventService()