
import { IonItem, IonButton, modalController, IonLabel } from '@ionic/vue'
import { defineComponent } from '@vue/runtime-core'
import { toRefs } from 'vue'
import { useI18n } from 'vue-i18n'

import Modal from './BaseModalSelect.vue'
import ProfessionSelector from '@/composables/ProfessionSelector'

export default defineComponent({
  components: {
    IonItem,
    IonButton,
    IonLabel,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: { type: Boolean, default: true },
    modelValue: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const { modelValue } = toRefs(props)
    const { prof, professionCountry } = ProfessionSelector(modelValue)

    const openModal = async () => {
      const modal = await modalController.create({
        component: Modal,
        componentProps: {
          title: props.placeholder,
          list: prof.value,
          selected: props.modelValue,
        },
      })

      await modal.present()
      const { data } = await modal.onDidDismiss()
      emit('update:modelValue', data)

    }

    return { openModal, t, prof, professionCountry }
  },
})
