
import {
  IonButton, IonCard, IonCardContent,
  IonContent,
  IonIcon,
  IonPage,
} from '@ionic/vue';
import { trash } from 'ionicons/icons';
import { computed, defineComponent, onMounted, ref } from 'vue';
import TheHeaderLarge from "@/components/TheHeaderLarge.vue";
import TheList from "@/components/List/TheList.vue";
import { ItemsListPresentation } from "@/components/List/ItemList";
import { useRoute } from "vue-router";
import useMyAgenda from "@/composables/useMyAgenda";
import { useI18n } from "vue-i18n";
import AppInfoGtm from "@/components/GTM/AppInfoGtm.vue";

export default defineComponent({
  components: {
    AppInfoGtm,
    TheList,
    TheHeaderLarge,
    IonContent,
    IonPage,
    IonButton,
    IonIcon,
    IonCard,
    IonCardContent,
  },
  setup() {
    const { t } = useI18n()
    const route = useRoute()
    const eventId = route.params.eventId.toString()
    const list = ref<ItemsListPresentation[]>([])
    const { seePresentations, removePresentation } = useMyAgenda(eventId)

    const myAendaPresentationList = computed(() => {
      if ( !list.value.length ) {
        return []
      }

      return list.value.map((data) => {
        const _data = Object.assign({}, data)
        _data.start = data.extraData.day
        _data.hour = data.hour
        return _data
      })
    })

    onMounted(async () => {
      list.value = seePresentations()
    })

    return {
      t,
      myAendaPresentationList,
      trash,
      removePresentation
    }
  }
});
