<template>
  <ion-grid>
    <ion-card>
      <ion-card-header>
        <slot name="header">
          <ion-card-title color="primary">
            <h1 v-html="category"></h1>
          </ion-card-title>
          <ion-card-title>
            <h4>{{ title }}</h4>
          </ion-card-title>
          <ion-card-subtitle color="medium">
            <h4>{{ subtitle }}</h4>
          </ion-card-subtitle>
        </slot>
      </ion-card-header>
      <ion-card-content class="ion-no-padding">
        <slot name="content">
          {{ description }}
        </slot>
      </ion-card-content>
    </ion-card>
  </ion-grid>
</template>

<script lang="ts">

import { defineComponent } from "vue";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonGrid,
} from "@ionic/vue";

export default defineComponent({
  name: 'TheCard',
  components: {
    IonGrid,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
  },
  props: {
    category: String,
    title: String,
    subtitle: String,
    description: String,
  }
})
</script>

<style scoped>
p {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 1em;
}

ion-card-title {
  font-size: 1.3rem;
}
</style>