import { onMounted, ref } from "vue";
import { loadingController } from "@ionic/vue";
import { eventService } from "@/services/event.service";
import useSleep from "@/composables/useSleep";
import { useStore } from "vuex";
import { EventShow } from "@/interfaces/EventShow";

export default function useLoadEvent(eventId: number | string) {
  const event = ref<EventShow>()
  const { sleep } = useSleep()
  const { commit } = useStore()

  onMounted(async () => {
    const loading = await loadingController
      .create({
        cssClass: 'my-custom-class',
        message: 'Cargando',
      });

    await loading.present();
    await sleep(1000)
    event.value = await eventService.show(eventId)
    commit('SET_EVENT', event.value)
    await loading.dismiss();
  })

  return {
    event
  }

}