<template>
  <div v-if="form">
    <Form @submit="onSubmit">
      <ion-card>
        <ion-card-header>
          <h2>{{ t('textos.titulos.informacionPersonal') }}</h2>
        </ion-card-header>
        <ion-card-content>
          <AppEmail
              v-model="state.authUser.email"
              label="formularios.email.label"
              placeholder="formularios.email.placeholder"
              disabled
          />
          <AppInputText
              v-model="state.authUser.nombre"
              label="formularios.nombre.label"
              placeholder="formularios.nombre.placeholder"
              disabled
          />
          <AppInputText
              v-model="state.authUser.apellido"
              label="formularios.apellido.label"
              placeholder="formularios.apellido.placeholder"
              disabled
          />
          <AppGenderSelect
              v-model="form.gender"
              label="formularios.genero.label"
              placeholder="formularios.genero.placeholder"
              required
          />
          <AppInputFecha
              v-model="form.birthDate"
              label="formularios.fechaNacimiento.label"
              placeholder="formularios.fechaNacimiento.placeholder"
              required
          />
          <AppIdiomSelect
              v-model="form.lang"
              label="formularios.idioma.label"
              placeholder="formularios.idioma.placeholder"
              required
          />
          <AppCountrySelect
              v-model="form.country"
              label="formularios.paisResidencia.label"
              placeholder="formularios.paisResidencia.placeholder"
              required
          />
          <AppInputText
              v-model="form.cityResidence"
              label="formularios.ciudadResidencia.label"
              placeholder="formularios.ciudadResidencia.placeholder"
          />
        </ion-card-content>
      </ion-card>
      <ion-card>
        <ion-card-header>
          <h2>{{ t('textos.titulos.datosDeContacto') }}</h2>
        </ion-card-header>
        <ion-card-content>
          <AppInputTel
              v-model="form.tel"
              label="formularios.telefono.label"
              placeholder="formularios.telefono.placeholder"
          />
          <AppInputTel
              v-model="form.telAlt"
              label="formularios.telefonoAlternativo.label"
              placeholder="formularios.telefonoAlternativo.placeholder"
              :required="false"
          />
        </ion-card-content>
      </ion-card>
      <ion-card>
        <ion-card-header>
          <h2>{{ t('textos.titulos.redesSociales') }}</h2>
        </ion-card-header>
        <ion-card-content>
          <AppInputUrl
              v-model="form.telegram"
              label="formularios.telegram.label"
              placeholder="formularios.telegram.placeholder"
              :required="false"
          />
          <AppInputUrl
              v-model="form.facebook"
              label="formularios.facebook.label"
              placeholder="formularios.facebook.placeholder"
              :required="false"
          />
          <AppInputUrl
              v-model="form.twitter"
              label="formularios.twitter.label"
              placeholder="formularios.twitter.placeholder"
              :required="false"
          />
          <AppInputUrl
              v-model="form.linkedin"
              label="formularios.linkedin.label"
              placeholder="formularios.linkedin.placeholder"
              :required="false"
          />
          <AppInputUrl
              v-model="form.instagram"
              label="formularios.instagram.label"
              placeholder="formularios.instagram.placeholder"
              :required="false"
          />
        </ion-card-content>
      </ion-card>
      <ion-card>
        <ion-card-header>
          <h2>{{ t('textos.titulos.informacionLaboral') }}</h2>
        </ion-card-header>
        <ion-card-content>
          <AppInputText
              v-model="form.currentJob"
              label="formularios.trabajoActualTexto.label"
              placeholder="formularios.trabajoActualTexto.placeholder"
          />
        </ion-card-content>
      </ion-card>
      <ion-card>
        <ion-card-header>
          <h2>{{ t('textos.titulos.interesesEnElEvento') }}</h2>
        </ion-card-header>
        <ion-card-content>
          <AppInterestRadio
              v-model="form.interest"
              label="formularios.interes.label"
              placeholder="formularios.interes.placeholder"
              disabled
          />
        </ion-card-content>
      </ion-card>
      <ion-card>
        <ion-card-header>
          <h2>{{ t('textos.titulos.compartirDatos') }}</h2>
        </ion-card-header>
        <ion-card-content>
          <AppCheckBox
              v-model="form.shareData"
              label="formularios.compartirDatos.label"
          />
        </ion-card-content>
      </ion-card>
      <div class="ion-padding-vertical">
        <ion-card-content class="ion-text-center">
          <ion-button type="submit">
            {{ t('botones.guardar') }}
          </ion-button>
        </ion-card-content>
      </div>
    </Form>
  </div>
  <div v-else>

  </div>
</template>

<script lang="ts">
import AppCountrySelect from "@/components/Form/AppCountrySelect.vue";
import AppGenderSelect from "@/components/Form/AppGenderSelect.vue";
import AppInputText from "@/components/Form/AppInputText.vue";
import AppIdiomSelect from "@/components/Form/AppIdiomSelect.vue";
import AppInputFecha from "@/components/Form/AppInputFecha.vue";
import { onMounted, ref, defineComponent } from "vue";
import { useForm, Form } from "vee-validate";
import { IonCard, IonCardContent, IonCardHeader, IonButton, loadingController } from "@ionic/vue";
import AppEmail from "@/components/Form/AppEmail.vue";
import { useI18n } from "vue-i18n";
import AppInputTel from "@/components/Form/AppInputTel.vue";
import AppInterestRadio from "@/components/Form/AppInterestRadio.vue";
import AppCheckBox from "@/components/Form/AppCheckBox.vue";
import AppInputUrl from "@/components/Form/AppInputUrl.vue";
import { Profile } from "@/interfaces/Profile";
import { profielesService } from "@/services/profiles.service";
import { useStore } from "vuex";


export default defineComponent({
  components: {
    AppInputUrl,
    AppCheckBox,
    AppInterestRadio,
    AppInputTel,
    AppEmail,
    IonCard,
    IonCardHeader,
    IonCardContent,
    AppInputFecha,
    AppIdiomSelect,
    AppInputText,
    AppGenderSelect,
    AppCountrySelect,
    Form,
    IonButton
  },
  setup() {
    const { t } = useI18n()
    const required = ref(true)
    const form = ref<Profile>()
    const { handleSubmit } = useForm()
    const { state } = useStore()

    const onSubmit = handleSubmit(async () => {
      if ( form.value ) {
        const loading = await loadingController
            .create({
              message: t('loading.actualizandoPerfil'),
            });
        await loading.present();
        try {
          await profielesService.update(form.value)
        } finally {
          await loading.dismiss();
        }
      }
    })

    onMounted(async () => {
      const loading = await loadingController
          .create({
            message: t('loading.cargandoProfile'),
          });

      await loading.present();
      try {
        form.value = await profielesService.load(state.locale)
      } finally {
        await loading.dismiss();
      }
    })

    return {
      state,
      t,
      required,
      form,
      onSubmit,
    }
  }
})
</script>
