<template>
  <ion-list class="ion-no-margin ion-no-padding" lines="full">
    <template v-for="item in itemsList" :button="item.link" :key="item.title">
      <ion-item
          button
          @click="simplePush(item.link)"
          :color="!item.link ? 'light' : (item.important)? 'primary' : '' "
          class="ion-text-wrap"
      >
        <template v-if="item.thumb">
          <ion-avatar slot="start">
            <img :src=" item.thumb">
          </ion-avatar>
        </template>
        <template v-else-if="item.start && !item.hour">
          <ion-note slot="start">
            <h6 v-html="item.start"></h6>
          </ion-note>
        </template>
        <template v-else-if="item.hour">
          <ion-note slot="start">
            <template v-if="item.start">
              <h6 v-html="item.start"></h6>
            </template>
            <h6 v-html="formatHourHtml(item.hour)"></h6>
          </ion-note>
        </template>

        <ion-label>
          <h6 class="ion-text-uppercase category" v-if="item.category">
            {{ item.category }}
          </h6>
          <h2>{{ item.title }}</h2>
          <template v-if="item.subtitle">
            {{ item.subtitle }}
          </template>
        </ion-label>
        <slot name="action" :item="item"></slot>
      </ion-item>
    </template>
  </ion-list>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonAvatar, IonCol, IonGrid, IonItem, IonLabel, IonList, IonNote, IonRow } from "@ionic/vue";
import { ItemsList } from "@/components/List/ItemList";
import UsePushLink from "@/composables/usePushLink";
import useDateManager from "@/composables/useDateManager";
import moment from "moment-timezone";

export default defineComponent({
  name: 'TheList',
  components: {
    IonItem,
    IonLabel,
    IonList,
    IonNote,
    IonAvatar,
  },
  props: {
    itemsList: Object as () => ItemsList[]
  },
  setup() {
    const { simplePush } = UsePushLink()
    const { formatHourHtml } = useDateManager()

    return {
      simplePush,
      formatHourHtml
    }
  }
})
</script>


<style scoped>
ion-note {
  width: 25%;
  text-align: center;
}

.ion-color-primary ion-note, .ion-color-primary .category {
  color: #ffffff;
}

.category {
  color: var(--ion-color-primary);
}
</style>