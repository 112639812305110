<template>
  <ion-page>
    <TheHeaderLarge subseccion="menu.agenda"/>
    <ion-content fullscreen>
      <template v-if="myAendaPresentationList.length">
        <AppInfoGtm/>
        <TheList :items-list="myAendaPresentationList">
          <template v-slot:action="{ item }">
            <ion-button shape="round" color="danger" @click="$event.stopPropagation(); removePresentation(item.id)">
              quitar
              <ion-icon style="margin-left: 0.5em" :icon="trash"></ion-icon>
            </ion-button>
          </template>
        </TheList>
      </template>
      <template v-else>
        <ion-card>
          <ion-card-content class="ion-text-center">
            <h1>{{ t('agenda.sinEventos') }}</h1>
          </ion-card-content>
        </ion-card>
      </template>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButton, IonCard, IonCardContent,
  IonContent,
  IonIcon,
  IonPage,
} from '@ionic/vue';
import { trash } from 'ionicons/icons';
import { computed, defineComponent, onMounted, ref } from 'vue';
import TheHeaderLarge from "@/components/TheHeaderLarge.vue";
import TheList from "@/components/List/TheList.vue";
import { ItemsListPresentation } from "@/components/List/ItemList";
import { useRoute } from "vue-router";
import useMyAgenda from "@/composables/useMyAgenda";
import { useI18n } from "vue-i18n";
import AppInfoGtm from "@/components/GTM/AppInfoGtm.vue";

export default defineComponent({
  components: {
    AppInfoGtm,
    TheList,
    TheHeaderLarge,
    IonContent,
    IonPage,
    IonButton,
    IonIcon,
    IonCard,
    IonCardContent,
  },
  setup() {
    const { t } = useI18n()
    const route = useRoute()
    const eventId = route.params.eventId.toString()
    const list = ref<ItemsListPresentation[]>([])
    const { seePresentations, removePresentation } = useMyAgenda(eventId)

    const myAendaPresentationList = computed(() => {
      if ( !list.value.length ) {
        return []
      }

      return list.value.map((data) => {
        const _data = Object.assign({}, data)
        _data.start = data.extraData.day
        _data.hour = data.hour
        return _data
      })
    })

    onMounted(async () => {
      list.value = seePresentations()
    })

    return {
      t,
      myAendaPresentationList,
      trash,
      removePresentation
    }
  }
});
</script>

<style scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>