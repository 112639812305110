<template>
  <div class="ion-text-center">
    <ion-button
      router-direction="back"
      size="small"
      color="primary"
      shape="round"
      @click="pushTo()"
    >
      <ion-icon :icon="homeOutline" />
    </ion-button>
  </div>
</template>
<script lang="ts">

import { defineComponent } from "vue";
import { IonButton, IonIcon } from '@ionic/vue';
import { homeOutline } from 'ionicons/icons';
import usePushLink from "@/composables/usePushLink";

export default defineComponent({
  name: 'TheHomeButtom',
  components: {
    IonButton,
    IonIcon
  },
  setup() {
    const { toBack } = usePushLink()

    return {
      homeOutline,
      pushTo: toBack
    }

  }

})
</script>