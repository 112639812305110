<template>
  <ion-item :class="meta.validated && !meta.valid ? 'error' : ''">
    <ion-label
      position="stacked"
      class="ion-text-uppercase"
    >
      {{ t(label) }} {{ required ? '*' : '' }}
    </ion-label>
    <ion-input
      v-model="field"
      :placeholder="t(placeholder)"
      :disabled="disabled"
      :required="required"
    />
  </ion-item>
  <div class="ion-text-right">
    <span class="error-text">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script lang="ts">
import { IonLabel, IonInput, IonItem } from '@ionic/vue'
import { defineComponent, getCurrentInstance, watch } from 'vue'
import { defineRule, useField } from 'vee-validate'
import { required } from '@vee-validate/rules'
import { ComponentInternalInstance } from "@vue/runtime-core";
import { useI18n } from "vue-i18n";

defineRule('required', required)

export default defineComponent({
  components: {
    IonLabel,
    IonInput,
    IonItem,
  },
  props: {
    modelValue: {
      type: String
    },
    label: {
      type: String,
      default: 'Teléfono'
    },
    placeholder: {
      type: String,
      default: 'Ingrese un numero de telefono'
    },
    required: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const instance = getCurrentInstance() as ComponentInternalInstance;
    const { value: field, errorMessage, meta } = useField(
        'input-' + (instance) ? instance.uid.toString() : '0',
        { required: props.required },
        { initialValue: props.modelValue,
          label: t(props.label)
        },
    )

    watch(field, (value) => emit('update:modelValue', value))

    return {
      t,
      field,
      errorMessage,
      meta,
    }
  },
})
</script>
