import { computed } from "vue";
import moment from "moment-timezone";


export default function useDateManager(day?: number | string) {

  const dayData = computed(() => {
    if ( day ) {
      return moment.unix(parseInt(day.toString())).utc()
    } else {
      return moment({ hour: 0, minute: 0 }).utc(true)
    }
  })

  const hourLocalData = (hours: string) => {
    const [ _hour, _minute ] = hours.split(':')
    const utc = moment.utc({ hour: parseInt(_hour), minute: parseInt(_minute) })
    const local = utc.local()
    return local
  }

  const formatHourHtml = (hours: string) => {
    return hourLocalData(hours).format('H:mm') + '<br><small>GTM ' + moment().local().format('Z') + '</small>'
  }

  return {
    dayData,
    hourLocalData,
    formatHourHtml
  }
}