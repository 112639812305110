<template>
  <div class="ion-margin-vertical">
    <div>
      <small>{{ t('idiomas.veridiomas') }}</small>
    </div>
    <ion-chip color="light">
      <ion-label>{{ t('idiomas.es') }}</ion-label>
    </ion-chip>
    <ion-chip color="light">
      <ion-label>{{ t('idiomas.en') }}</ion-label>
    </ion-chip>
  </div>
</template>

<script lang="ts">

import { defineComponent } from "vue";
import { IonChip, IonLabel } from "@ionic/vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: 'TheLangSelector',
  components: {
    IonChip,
    IonLabel
  },
  setup(){
    const { t } = useI18n()

    return {
      t
    }
  }
})
</script>