
import {
  IonContent,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton
} from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { calendar, personCircle } from 'ionicons/icons';
import AppEmail from "@/components/Form/AppEmail.vue";
import AppPassword from "@/components/Form/AppPassword.vue";
import TheFooter from "@/components/TheFooter.vue";
import TheHomeButtom from "@/components/TheHomeButtom.vue";
import { useI18n } from "vue-i18n";
import { useForm, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: 'Login',
  components: {
    TheHomeButtom,
    TheFooter,
    AppPassword,
    AppEmail,
    IonContent,
    IonPage,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    Form
  },
  setup() {
    const { t } = useI18n()
    const { handleSubmit } = useForm()
    const email = ref('')
    const password = ref('')
    const { dispatch } = useStore()
    const router = useRouter()

    const onSubmit = handleSubmit(async () => {
      const login = await dispatch('login', { email: email.value, password: password.value })
      if(login){
        await router.go(-1)
      }
    });

    return {
      t,
      calendar,
      personCircle,
      onSubmit,
      email,
      password
    }
  }

});
