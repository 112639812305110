<template>
  <IonPage>
    <ion-content
      class="ion-align-items-center"
      color="primary"
      :fullscreen="true"
    >
      <ion-grid id="container">
        <ion-row class="ion-align-items-center ion-justify-content-center">
          <ion-col
            size="12"
            size-md="8"
            size-lg="4"
          >
            <TheHomeButtom />
            <IonCard>
              <ion-card-header>
                <img
                  src="/img/logo.png"
                  class="im"
                  height="65"
                >
              </ion-card-header>

              <ion-card-content>
                <ion-grid>
                  <Form @submit="onSubmit">
                    <ion-row class="ion-justify-content-center">
                      <ion-col
                        size="12"
                        size-sm="10"
                        size-md="12"
                        size-lg="12"
                      >
                        <AppEmail v-model="email" />
                        <AppPassword v-model="password" />
                        <div class="ion-text-center ion-margin-vertical">
                          <ion-button
                            size="medium"
                            type="submit"
                            color="primary"
                          >
                            {{ t('botones.ingresar') }}
                          </ion-button>
                        </div>
                        <div class="ion-text-center ion-margin-vertical">
                          <ion-button
                              size="small"
                              color="success"
                              href="https://admin.mundoconectado.org/2/registros"
                              target="_blank"
                          >
                            {{ t('botones.registrarse') }}
                          </ion-button>
                        </div>
                        <div>
                          <a href="https://admin.mundoconectado.org/recuperacion/" target="_blank">{{ t('botones.recueracion') }}</a>
                        </div>
                      </ion-col>
                    </ion-row>
                  </Form>
                </ion-grid>
              </ion-card-content>
            </IonCard>
            <TheFooter />
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </IonPage>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton
} from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { calendar, personCircle } from 'ionicons/icons';
import AppEmail from "@/components/Form/AppEmail.vue";
import AppPassword from "@/components/Form/AppPassword.vue";
import TheFooter from "@/components/TheFooter.vue";
import TheHomeButtom from "@/components/TheHomeButtom.vue";
import { useI18n } from "vue-i18n";
import { useForm, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: 'Login',
  components: {
    TheHomeButtom,
    TheFooter,
    AppPassword,
    AppEmail,
    IonContent,
    IonPage,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    Form
  },
  setup() {
    const { t } = useI18n()
    const { handleSubmit } = useForm()
    const email = ref('')
    const password = ref('')
    const { dispatch } = useStore()
    const router = useRouter()

    const onSubmit = handleSubmit(async () => {
      const login = await dispatch('login', { email: email.value, password: password.value })
      if(login){
        await router.go(-1)
      }
    });

    return {
      t,
      calendar,
      personCircle,
      onSubmit,
      email,
      password
    }
  }

});
</script>

<style scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
</style>