<template>
  <ion-app>
    <TheMenuUser v-if="state.authUser" />
    <ion-router-outlet id="main" />
  </ion-app>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import ToastConnectionStatus from "@/composables/ToastConnectionStatus";
import TheMenuUser from "@/components/Menu/TheMenuUser.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: 'App',
  components: {
    TheMenuUser,
    IonApp,
    IonRouterOutlet
  },
  setup() {
    const { state } = useStore()
    const { modoOnLine, modoOffLine } = ToastConnectionStatus()

    if ( !navigator.onLine )
      modoOffLine()

    window.addEventListener('offline', () => {
      modoOffLine()
    })

    window.addEventListener('online', () => {
      modoOnLine()
    })

    return {
      state
    }
  }
});
</script>