import services from "@/services/services";
import axios from '@/plugins/axios'
import { Speaker } from "@/interfaces/Program/Speaker";

class SpeakerService extends services {

  list(eventoId: string|number): Promise<Speaker[]>  {

    const promesa = axios.get('eventos/' + eventoId + '/expositores', { data: { noCapturar: true } })

    return promesa.then((respuesta)=>{
      return respuesta.data
    }).catch((e) => {
      console.info(e)
      throw new Error('Error')
    })
  }

  show(eventoId: string|number, speakerId: string|number ): Promise<Speaker>  {

    const promesa = axios.get('eventos/' + eventoId + '/expositores/' + speakerId, { data: { noCapturar: true } })

    return promesa.then((respuesta)=>{
      return respuesta.data
    }).catch((e) => {
      console.info(e)
      throw new Error('Error')
    })
  }

}

export const speakerService = new SpeakerService()