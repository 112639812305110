<template>
  <ion-page>
    <TheHeaderLarge subseccion="menu.asistente" />
    <ion-content fullscreen>
      <div v-if="assistant">
        <TheCard>
          <template #header>
            <ion-avatar style="margin: 1.5em auto; width: 100px; height: 100px">
              <img :src="assistant.thumb ? assistant.thumb : '/img/avatar.svg'">
            </ion-avatar>
          </template>
          <template #content>
            <div class="ion-margin ion-text-center">
              <h4>Nombre y apellido</h4>
              <h1>{{ assistant.nombre }} {{ assistant.apellido }}</h1>
            </div>

            <div
              v-if="assistant.compartirDatos"
              class="ion-margin ion-text-center"
            >
              <ion-button
                v-if="assistant.email"
                size="large"
                fill="clear"
                target="_blank"
                :href="'mailto:' + assistant.email"
              >
                Email
                <ion-icon :icon="mail" />
              </ion-button>
              <ion-button
                v-if="assistant.telegram"
                size="large"
                fill="clear"
                target="_blank"
                :href="assistant.telegram"
              >
                Telegram
                <img
                  src="/img/telegram.svg"
                  height="45"
                >
              </ion-button>
            </div>
          </template>
        </TheCard>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonAvatar, IonButton, IonContent, IonIcon, IonPage, IonText } from "@ionic/vue";
import { defineComponent, onMounted, ref } from "vue";
import TheHeaderLarge from "@/components/TheHeaderLarge.vue";
import { useRoute } from "vue-router";
import TheCard from "@/components/Card/TheCard.vue";
import { Assistant } from "@/interfaces/Assistant";
import { assistantsService } from "@/services/assistants.service";
import { mail } from 'ionicons/icons';

export default defineComponent({
  components: {
    TheCard,
    TheHeaderLarge,
    IonContent,
    IonPage,
    IonAvatar,
    IonButton,
    IonIcon
  },
  setup() {
    const route = useRoute()
    const eventId: string = route.params.eventId.toString()
    const assistantId: string = route.params.assistantId.toString();
    const assistant = ref<Assistant>()

    onMounted(async () => {
      assistant.value = await assistantsService.show(eventId, assistantId)
    })

    return {
      assistant,
      mail
    }
  }
})
</script>

<style scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>
