
import { IonAvatar, IonButton, IonContent, IonIcon, IonPage, IonText } from "@ionic/vue";
import { defineComponent, onMounted, ref } from "vue";
import TheHeaderLarge from "@/components/TheHeaderLarge.vue";
import { useRoute } from "vue-router";
import TheCard from "@/components/Card/TheCard.vue";
import { Assistant } from "@/interfaces/Assistant";
import { assistantsService } from "@/services/assistants.service";
import { mail } from 'ionicons/icons';

export default defineComponent({
  components: {
    TheCard,
    TheHeaderLarge,
    IonContent,
    IonPage,
    IonAvatar,
    IonButton,
    IonIcon
  },
  setup() {
    const route = useRoute()
    const eventId: string = route.params.eventId.toString()
    const assistantId: string = route.params.assistantId.toString();
    const assistant = ref<Assistant>()

    onMounted(async () => {
      assistant.value = await assistantsService.show(eventId, assistantId)
    })

    return {
      assistant,
      mail
    }
  }
})
