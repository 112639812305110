import jwtDecode from "jwt-decode";

const isEmpty = (obj) => {
  return Object.keys(obj).length === 0;
}

const getters = {
  avatar: state => {
    return ( !isEmpty(state.authUser) || state.authUser.avatar)? state.authUser.avatar : false
  },
  datosToken: state => {
    if ( !isEmpty(state.authUser) ) {
      return jwtDecode(state.authUser.token)
    }
    return false
  },
}

export default getters