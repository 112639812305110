import store from './store'
import axios from 'axios'

const apiUrl = '//emcapi.div-it.com.ar/app/v1/'

const option = { baseURL: apiUrl }

const CancelToken = axios.CancelToken
const instance = axios.create(option)

instance.interceptors.request.use(function (config) {

  if (typeof store.state.locale !== 'undefined') {
    Object.assign(config.headers, { 'Accept-Language': store.state.locale })
  }

  if (
    typeof store.state.authUser !== 'undefined' &&
    typeof store.state.authUser.token !== 'undefined'
  ) {
    Object.assign(config.headers, { Authorization: 'Bearer ' + store.state.authUser.token })
  }

  // Creamos un nuevo Source para cada paticion y asi permitir la cancelación
  const source = CancelToken.source();
  Object.assign(config, { cancelToken: source.token })

  return config
}, function (error) {
  return Promise.reject(error)
})

export default instance
