

import { defineComponent, watch } from "vue";
import { IonInput, IonLabel, IonItem } from "@ionic/vue";
import { defineRule, useField } from "vee-validate";
import { required } from "@vee-validate/rules";
import { useI18n } from "vue-i18n";

defineRule('required', required)

export default defineComponent({
  name: 'AppPassword',
  components: {
    IonLabel,
    IonInput,
    IonItem
  },
  props: {
    modelValue: {
      type: String,
    },
    label: {
      type: String,
      default: 'formularios.password.label',
    },
    placeholder: {
      type: String,
      default: 'formularios.password.placeholder',
    },
    required: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const { value: field, errorMessage, meta } = useField(
        'input-pass',
        { required: props.required },
        {
          initialValue: props.modelValue,
          label: t(props.label)
        },
    )

    watch(field, (val) => emit('update:modelValue', val))

    return {
      t,
      field,
      errorMessage,
      meta,
    }
  },
})
