<template>
  <IonPage>
    <ion-content
      class="ion-align-items-center"
      color="primary"
      :fullscreen="true"
    >
      <ion-grid id="container">
        <ion-row class="ion-align-items-center ion-justify-content-center">
          <ion-col
            size="12"
            size-md="8"
            size-lg="4"
          >
            <TheHomeButtom />
            <IonCard>
              <ion-card-header>
                <img
                  src="/img/logo.png"
                  class="im"
                  height="65"
                >
                <ion-text color="primary">
                  <h6>Registro</h6>
                </ion-text>
              </ion-card-header>

              <ion-card-content>
                <ion-grid>
                  <ion-row class="ion-justify-content-center">
                    <ion-col
                      size="12"
                      size-sm="10"
                      size-md="12"
                      size-lg="12"
                    />
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </IonCard>
            <TheLangSelector />
            <TheFooter />
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </IonPage>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol, IonText
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { calendar, personCircle } from 'ionicons/icons';
import TheLangSelector from "@/components/TheLangSelector.vue";
import TheFooter from "@/components/TheFooter.vue";
import TheHomeButtom from "@/components/TheHomeButtom.vue";

export default defineComponent({
  name: 'Registration',
  components: {
    TheHomeButtom,
    TheFooter,
    TheLangSelector,
    IonContent,
    IonPage,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonText
  },
  setup() {
    console.log('Registration')

    const beforeTabChange = () => {
      // do something before tab change
    }
    const afterTabChange = () => {
      // do something after tab change
    }

    return {
      calendar,
      personCircle,
      beforeTabChange,
      afterTabChange
    }
  }

});
</script>

<style scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
</style>