/* eslint-disable no-console */

import { register } from 'register-service-worker'
import { alertController } from '@ionic/vue'

if ( process.env.NODE_ENV === 'production') {

  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered() {
      console.log('Service worker has been registered.....')
    },
    cached() {
      console.log('Content has been cached for offline use.')
      new Notification('cached');

    },
    updatefound() {
      console.log('New content is downloading!')

    },
    async updated() {
      caches.keys().then(function(names) {
        for (const name of names) caches.delete(name);
      })

      const alert = await alertController
        .create({
          backdropDismiss: false,
          header: 'Nuevo contenido disponible',
          message: 'Por favor cierre y vuelva a abrir la aplicación para aplicar las nuevas actualizaciones',
        });

      await alert.present();
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
      new Notification('offline');
    },
    error(error) {
      console.error('Error during service worker registration:', error)
      new Notification('error');

    }
  })


}
