import { ref, onMounted } from 'vue'
import axios from '@/plugins/axios'
import { SelectedOption, SelectedOptionAutocompletar } from "@/interfaces/SelectedOption";

export default function GenderRepository() {
  const genders = ref<SelectedOption[]>([])

  const fetchGender = async () => {
    const { data } = await axios.get<SelectedOptionAutocompletar>('generos/autocompletar', { data: { noCapturar: true } })
    genders.value = data.listado.map((list) => {
      return {
        id: list.id,
        name: list.nombre
      }
    })
  }

  onMounted(fetchGender)

  return {
    genders,
  }
}
