
import AppCountrySelect from "@/components/Form/AppCountrySelect.vue";
import AppGenderSelect from "@/components/Form/AppGenderSelect.vue";
import AppInputText from "@/components/Form/AppInputText.vue";
import AppIdiomSelect from "@/components/Form/AppIdiomSelect.vue";
import AppInputFecha from "@/components/Form/AppInputFecha.vue";
import { onMounted, ref, defineComponent } from "vue";
import { useForm, Form } from "vee-validate";
import { IonCard, IonCardContent, IonCardHeader, IonButton, loadingController } from "@ionic/vue";
import AppEmail from "@/components/Form/AppEmail.vue";
import { useI18n } from "vue-i18n";
import AppInputTel from "@/components/Form/AppInputTel.vue";
import AppInterestRadio from "@/components/Form/AppInterestRadio.vue";
import AppCheckBox from "@/components/Form/AppCheckBox.vue";
import AppInputUrl from "@/components/Form/AppInputUrl.vue";
import { Profile } from "@/interfaces/Profile";
import { profielesService } from "@/services/profiles.service";
import { useStore } from "vuex";


export default defineComponent({
  components: {
    AppInputUrl,
    AppCheckBox,
    AppInterestRadio,
    AppInputTel,
    AppEmail,
    IonCard,
    IonCardHeader,
    IonCardContent,
    AppInputFecha,
    AppIdiomSelect,
    AppInputText,
    AppGenderSelect,
    AppCountrySelect,
    Form,
    IonButton
  },
  setup() {
    const { t } = useI18n()
    const required = ref(true)
    const form = ref<Profile>()
    const { handleSubmit } = useForm()
    const { state } = useStore()

    const onSubmit = handleSubmit(async () => {
      if ( form.value ) {
        const loading = await loadingController
            .create({
              message: t('loading.actualizandoPerfil'),
            });
        await loading.present();
        try {
          await profielesService.update(form.value)
        } finally {
          await loading.dismiss();
        }
      }
    })

    onMounted(async () => {
      const loading = await loadingController
          .create({
            message: t('loading.cargandoProfile'),
          });

      await loading.present();
      try {
        form.value = await profielesService.load(state.locale)
      } finally {
        await loading.dismiss();
      }
    })

    return {
      state,
      t,
      required,
      form,
      onSubmit,
    }
  }
})
