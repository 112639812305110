import { ref, onMounted } from 'vue'
import axios from '@/plugins/axios'
import { SelectedOption, SelectedOptionAutocompletar } from "@/interfaces/SelectedOption";

export default function InterestRadio() {
  const interests = ref<SelectedOption[]>([])

  const fetchInterest = async () => {
    const { data } = await axios.get<SelectedOptionAutocompletar>('sectores/autocompletar', { data: { noCapturar: true } })
    interests.value = data.listado.map((list) => {
      return {
        id: list.id,
        name: list.nombre
      }
    }).sort(
      (a, b) => parseInt(a.id.toString()) - parseInt(b.id.toString())
    )
  }

  onMounted(fetchInterest)
  return {
    interests
  }
}
