<template>
  <div class="ion-text-center ion-padding-vertical" style="background-color: #efefef;">
    <h5>{{ t('gtm.informacionGtm') }}</h5>
    <h6>{{ t('gtm.zonaHorariaActual') }} {{ moment().local().format('Z') }}</h6>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import moment from "moment-timezone";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n()

    return {
      t,
      moment
    }
  }
})
</script>