
import {
  IonContent, IonInfiniteScroll, IonInfiniteScrollContent,
  IonPage,
} from '@ionic/vue';
import { defineComponent, ref, onMounted } from 'vue';
import TheHeaderLarge from "@/components/TheHeaderLarge.vue";
import TheList from "@/components/List/TheList.vue";
import { ItemsList } from "@/components/List/ItemList";
import UsePushLink from "@/composables/usePushLink";
import { useRoute } from "vue-router";
import { assistantsService } from "@/services/assistants.service";

export default defineComponent({
  name: 'Speakers',
  components: {
    TheList,
    TheHeaderLarge,
    IonContent,
    IonPage,
    IonInfiniteScroll,
    IonInfiniteScrollContent
  },
  setup() {
    const route = useRoute()
    const eventId = route.params.eventId.toString()
    const { getUrl } = UsePushLink()
    const assistantsList = ref<ItemsList[]>([])
    const items = ref<ItemsList[]>([])
    const isDisabled = ref(false);
    const limit = ref(20);
    const page = ref(1);
    const isLimit = ref(false);

    const pushData = async () => {
      const assistantsData = await assistantsService.list(eventId, limit.value, page.value)

      if(assistantsData.length === 0){
        isLimit.value = true
        return
      }

      const formatDatas = assistantsData.map(( assistant) => {
        return {
          thumb: (assistant.thumb)? assistant.thumb : 'https://ionicframework.com/docs/demos/api/avatar/avatar.svg',
          title: assistant.nombre,
          link: getUrl('Assistant', { eventId: eventId, assistantId: assistant.id.toString() }),
        }
      })

      formatDatas.forEach((formatData) =>{
        assistantsList.value.push(formatData)
      })

      page.value++
    }

    const loadData = async (ev: CustomEvent) => {
      await pushData()
      const target = ev.target as HTMLIonInfiniteScrollElement
      await target.complete()
      // App logic to determine if all data is loaded
      // and disable the infinite scroll
      if (isLimit.value) {
        target.disabled = true
      }
    }

    onMounted(async ()=> {
      pushData()
    })

    return {
      assistantsList,
      items,
      loadData,
      isDisabled
    }
  }
});
