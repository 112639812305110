
import {
  IonCol,
  IonIcon,
  IonRow,
  IonButton,
} from '@ionic/vue';
import { computed, defineComponent } from "vue";
import {
  calendarOutline,
  helpOutline,
  videocamOutline,
  informationCircleOutline,
  schoolOutline,
  megaphoneOutline
} from 'ionicons/icons';
import UsePushLink from "@/composables/usePushLink";
import { useI18n } from "vue-i18n";
import youtubeLink from "@/model/youtubeLink";

export default defineComponent({
  name: 'ThebuttomNav',
  components: {
    IonRow,
    IonCol,
    IonIcon,
    IonButton,
  },
  setup() {

    const { t, locale } = useI18n()
    const { toPush } = UsePushLink()
    const session = computed(()=>{
      return youtubeLink[locale.value]
    })


    return {
      t,
      calendarOutline,
      helpOutline,
      videocamOutline,
      informationCircleOutline,
      schoolOutline,
      megaphoneOutline,
      toPush,
      session
    }
  }
})
