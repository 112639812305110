import services from "@/services/services";
import axios from "@/plugins/axios";
import { Profile } from "@/interfaces/Profile";
import { ResponseApi } from "@/interfaces/ResponseApi";

interface ProfileServer {
  generoId: number;
  fechaNacimiento: string | number;
  idiomaPreferido: string;
  paisResidenciaId: number;
  ciudadResidencia: string;
  telefono: string;
  telefonoAlternativo: string;
  telegram: string;
  facebook: string;
  twitter: string;
  linkeding: string;
  instagram: string;
  trabajoActualEs: string;
  sectorId: number;
  compartiDatosContacto: boolean;
}

class ProfilesService extends services {
  load(actualLang: string): Promise<Profile> {
    const promesa = axios.get("profile", { data: { noCapturar: true } });
    return promesa.then((respuesta) => {
      const profile: ProfileServer = respuesta.data
      let date
      if(typeof profile.fechaNacimiento !== 'undefined'){
        date = new Date(parseInt(profile.fechaNacimiento.toString()) * 1000).toISOString()
      }else{
        date = null
      }

      return {
        gender: profile.generoId,
        birthDate: date,
        lang: profile.idiomaPreferido ? profile.idiomaPreferido : actualLang,
        country: profile.paisResidenciaId,
        cityResidence: profile.ciudadResidencia,
        tel: profile.telefono,
        telAlt: profile.telefonoAlternativo,
        telegram: profile.telegram,
        facebook: profile.facebook,
        twitter: profile.twitter,
        linkedin: profile.linkeding,
        instagram: profile.instagram,
        currentJob: profile.trabajoActualEs,
        interest: profile.sectorId,
        shareData: profile.compartiDatosContacto,
      };
    });
  }

  update(form: Profile): Promise<string> {
    const dataToSend: ProfileServer = {
      generoId: form.gender,
      fechaNacimiento: Math.floor(new Date(form.birthDate).getTime() / 1000),
      idiomaPreferido: form.lang,
      paisResidenciaId: form.country,
      ciudadResidencia: form.cityResidence,
      telefono: form.tel,
      telefonoAlternativo: form.telAlt,
      telegram: form.telegram,
      facebook: form.facebook,
      twitter: form.twitter,
      linkeding: form.linkedin,
      instagram: form.instagram,
      trabajoActualEs: form.currentJob,
      sectorId: form.interest,
      compartiDatosContacto: form.shareData,
    };

    const promesa = axios.put("profile", dataToSend);
    return promesa.then((res) => {
      const responseApi: ResponseApi = res.data;
      return responseApi.mensaje;
    });
  }

  updateImg(img: Blob): Promise<string> {
    // cambiar la ruta en caso de que no sea esa
    const formData = new FormData();
    formData.append("file", img);
    return axios.post("profile/avatar", formData, { headers: {
        'X-NO-CAPTURE': 1
      }}).then((res) => {
      return res.data.fileUrl
    });
  }  
}

export const profielesService = new ProfilesService();
