export default {
  es: [
    "Abogada/o",
    "Actriz/ actor",
    "Administrador/a de fincas",
    "Administrativa/o comercial",
    "Administrativa/o de bancas",
    "Administrativa/o de gestión y personal",
    "Agente de bolsa",
    "Agente de seguros",
    "Agente de viajes",
    "Agrónoma/o",
    "Analista de mercados",
    "Animador/a sociocultural",
    "Antropóloga/o",
    "Archivera/o",
    "Arqueóloga/o",
    "Arquitecta/o, técnica/o",
    "Asesor/a de empresas",
    "Auxiliar de ayudas a domicilio",
    "Bailarín/a",
    "Bibliotecaria/o",
    "Bióloga/o",
    "Bombera/o",
    "Botánica/o",
    "Cámara",
    "Cantante",
    "Carpintera/o",
    "Cartógrafa/o",
    "Cocinera/o",
    "Compositor/a",
    "Consultor/a empresarial",
    "Contable",
    "Coreógrafa/o",
    "Criminóloga/o",
    "Decorador/a",
    "Delineante y diseñador/a técnica/o",
    "Deportista",
    "Dietista",
    "Director/a de cine/teatro",
    "Director/a de orquesta",
    "Diseñador/a de ropa",
    "Diseñador/a gráfico",
    "Documentalista",
    "Economista",
    "Educador/a infantil",
    "Electricista",
    "Enfermera/o",
    "Escenógrafa/o",
    "Escritor/a",
    "Escultor/a",
    "Estadística/o",
    "Esteticista",
    "Estilista",
    "Farmaceútica",
    "Filóloga/o",
    "Filósofa/o",
    "Física/o",
    "Fisioterapeuta",
    "Fontanera/o",
    "Fotógrafa/o",
    "Funcionaria/o de prisiones",
    "Geógrafa/o",
    "Geóloga/o",
    "Gerente de empresas",
    "Guardia de seguridad",
    "Guardia forestal",
    "Guionista",
    "Higienista dental",
    "Historiador/a",
    "Hostelera/o",
    "Ilustrador/a",
    "Informador/a turístico",
    "Informática/o",
    "Ingeniera/o",
    "Ingeniera/o aeronaútico",
    "Ingeniera/o en obras públicas",
    "Jardinera/o",
    "Juez/a",
    "Locutor/a",
    "Logopeda",
    "Maquillador/a",
    "Matemática/o",
    "Mecánica/o",
    "Médica/o",
    "Metalúrgica/o",
    "Militar",
    "Modelo",
    "Música/o",
    "Notaria/o",
    "Nutricionista",
    "Odontóloga/o",
    "Oftalmóloga/o",
    "Pedagoga/o",
    "Pediatra",
    "Peluquera/o",
    "Periodista",
    "Pintor/a artístico/a",
    "Podóloga/o",
    "Policía",
    "Preparador/a físico/a deportivo/a",
    "Procurador/a",
    "Profesor/a",
    "Protésica/o dental",
    "Psicóloga/o",
    "Psiquiatra",
    "Publicitaria/o",
    "Química/o",
    "Redactor/a",
    "Relaciones públicas",
    "Restaurador/a",
    "Secretario/a",
    "Socióloga/o",
    "Socorrista",
    "Técnica en laboratorio de imagen",
    "Técnica/o de laboratorio",
    "Técnica/o de sonido",
    "Técnica/o en electrónica",
    "Técnica/o en prevención de riesgos laborales",
    "Técnica/o en tratamiento de imagen",
    "Topógrafa/o",
    "Trabajador/a social",
    "Traductor/a",
    "Veterinaria/o"
  ],
  en: [
    "Lawyer",
    "Actor",
    "Farm administrator",
    "Commercial administrative",
    "Administrative of banks",
    "Administrative management and personnel",
    "Broker",
    "Insurance agent",
    "Travel agent",
    "Agronomist",
    "Market analyst",
    "Sociocultural animator",
    "Anthropologist",
    "Archivist",
    "Archaeologist",
    "Technical architect",
    "Business consultant",
    "Home aid assistant",
    "Dancer",
    "Librarian",
    "Biologist",
    "Firefighter",
    "Botanical",
    "Camera",
    "Singer",
    "Carpenter",
    "Cartographer",
    "Chef",
    "Composer",
    "Business Consultant",
    "Accountant",
    "Choreographer",
    "Criminologist",
    "Decorator",
    "Draftsman and technical designer",
    "Sports player",
    "Dietitian",
    "Film / theater director",
    "Orchestra director",
    "Clothing designer",
    "Graphic designer",
    "Documentalist",
    "Economist",
    "Child educator",
    "Electrician",
    "Nurse",
    "Scenographer",
    "Writer",
    "Sculptor",
    "Statistical",
    "Beautician",
    "Stylist",
    "Pharmaceutical",
    "Philologist",
    "Philosopher",
    "Physical",
    "Physiotherapist",
    "Plumber",
    "Photographer",
    "Prison officer",
    "Geographer",
    "Geologist",
    "Business manager",
    "Security guard",
    "Forest guard",
    "Screenwriter",
    "Dental hygienist",
    "Historian",
    "Hostelero",
    "Illustrator",
    "Tourist guide",
    "IT",
    "Engineer",
    "Aeronautic engineer",
    "Engineer in public works",
    "Gardener",
    "Judge",
    "Announcer",
    "Speech therapist",
    "Makeup artist",
    "Mathematical",
    "Mechanical",
    "Doctor",
    "Metallurgical",
    "Military",
    "Model",
    "Musician",
    "Notary",
    "Nutritionist",
    "Dentist",
    "Ophthalmologist",
    "Pedagogue",
    "Pediatrician",
    "Hairdresser",
    "Journalist",
    "Art painter",
    "Chiropodist",
    "Policeman",
    "Sports physical trainer",
    "Attorney",
    "Teacher",
    "Dental protestic",
    "Psychologist",
    "Psychiatrist",
    "Advertising",
    "Chemical",
    "Editor",
    "Public relations",
    "Restorer",
    "Secretary",
    "Sociologist",
    "Life guard",
    "Technique in imaging laboratory",
    "Laboratory technician",
    "Sound technician",
    "Electronics Technician",
    "Technician in prevention of occupational risks",
    "Image processing technician",
    "Surveyor",
    "Social worker",
    "Translator",
    "Veterinarian"
  ]
}
