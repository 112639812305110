
import {
  IonCard,
  IonCardContent,
  IonContent,
  IonPage,
} from '@ionic/vue';
import { defineComponent, onMounted, ref } from 'vue';
import TheHeaderLarge from "@/components/TheHeaderLarge.vue";
import TheList from "@/components/List/TheList.vue";
import { ItemsList } from "@/components/List/ItemList";
import UsePushLink from "@/composables/usePushLink";
import { useRoute } from "vue-router";
import { speakerService } from "@/services/speaker.service";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: 'Speakers',
  components: {
    TheList,
    TheHeaderLarge,
    IonContent,
    IonPage,
    IonCard,
    IonCardContent
  },
  setup() {
    const { t } = useI18n()
    const route = useRoute()
    const eventId = route.params.eventId.toString()
    const { getUrl } = UsePushLink()
    const speakersList = ref<ItemsList[]>([])

    onMounted(async () => {
      const speakersData = await speakerService.list(eventId)
      speakersList.value = speakersData.map((speaker) => {
        return {
          thumb: (speaker.thumb) ? speaker.thumb : 'https://ionicframework.com/docs/demos/api/avatar/avatar.svg',
          title: speaker.nombre,
          link: getUrl('Speaker', { eventId: eventId, speakerId: speaker.id.toString() }),
          subtitle: speaker.cargo
        }
      })
    })


    return {
      t,
      speakersList
    }
  }
});
