<template>
  <Field
      v-slot="{ field, meta,errorMessage }"
      v-model="val"
      name="gender"
      :rules="{ required: required, oneOf: oneOf }"
      :label="t(label)"
  >
    <ion-item :class="meta.validated && !meta.valid ? 'error' : ''">
      <ion-label class="ion-text-uppercase">
        {{ t(label) }} {{ required ? "*" : "" }}
      </ion-label>
      <ion-select
          v-if="genders.length"
          v-bind="field"
          :disabled="disabled"
          @ionChange="setSelectedGender"
      >
        <ion-select-option
            v-for="gender of genders"
            :key="gender.id"
            :value="gender.id"
        >
          {{ gender.name }}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <div class="ion-text-right">
      <span class="error-text">
        {{ errorMessage }}
      </span>
    </div>
  </Field>
</template>

<script lang="ts">
import { IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/vue";
import { defineComponent, ref, watch } from "vue";
import { defineRule, Field } from "vee-validate";
import { required, one_of as oneOf } from "@vee-validate/rules";

import GenderRepository from "@/composables/GenderRepository";
import { useI18n } from "vue-i18n";

defineRule('required', required);
defineRule('oneOf', oneOf);

export default defineComponent({
  components: {
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    Field
  },
  props: {
    modelValue: [ Number, String ],
    label: {
      type: String,
      default: "Seleccione un Genero",
    },
    required: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const { genders } = GenderRepository();
    const val = ref(props.modelValue)
    const oneOf = ref<(number | string)[]>([]);

    watch(genders, () => {
      oneOf.value = Array.from(genders.value.map((gender) => gender.id))
    })

    const setSelectedGender = (event: CustomEvent) => {
      emit("update:modelValue", event.detail.value)
    }
    return {
      t,
      oneOf,
      val,
      genders,
      setSelectedGender
    };
  },
});
</script>
